import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setProgress } from "../../slices/loader";
import { register } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import classes from "./Register.module.css";
import { renderToString } from 'react-dom/server';

import Footer from '../../components/footer/footer';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const Register = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [successful, setSuccessful] = useState(false);
  const { progress } = useSelector((state) => state.loader);
  const { message } = useSelector((state) => state.message);
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  const referral = searchParams.get(`referral`);


  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);



  const initialValues = {
    username: "",
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    date_of_birth: "",
    phone: "",
    terms_conditions: false,
    sms_consent: false
  };

  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .test(
        "len",
        "The firstname must be between 3 and 20 characters.",
        (val) =>
          val && val.toString().length >= 3 && val.toString().length <= 20
      )
      .required("This field is required!"),
    lastname: Yup.string()
      .test(
        "len",
        "The lastname must be between 3 and 20 characters.",
        (val) =>
          val && val.toString().length >= 3 && val.toString().length <= 20
      )
      .required("This field is required!"),
    username: Yup.string()
      .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val) =>
          val && val.toString().length >= 3 && val.toString().length <= 20
      )
      .required("This field is required!"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("This field is required!"),
    password: Yup.string()
      .matches(passwordRules, { message: "Please create a stronger password (min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.)" })
      .required('This field is required!'),
    date_of_birth: Yup.string()
      .required("This field is required!"),
    phone: Yup.string()
      .required("This field is required!"),
    terms_conditions: Yup.string()
      .required("This field is required!")
  });

  const handleRegister = (formValue) => {
    const {
      username,
      email,
      password,
      firstname,
      lastname,
      date_of_birth,
      phone
    } = formValue;

    setSuccessful(false);
    dispatch(setProgress(progress + 50));

    dispatch(register({
      username: username,
      email: email,
      password: password,
      firstName: firstname,
      lastName: lastname,
      date_of_birth: date_of_birth,
      phone: phone,
      referral: referral ? referral : null
    }))
      .unwrap()
      .then((originalPromiseResult) => {
        setSuccessful(true);

        if (referral) {
          searchParams.delete('referral')
          setSearchParams(searchParams);
        }

        dispatch(setProgress(100));
      })
      .catch((rejectedValueOrSerializedError) => {
        setSuccessful(false);
        dispatch(setProgress(100));
      });
  };

  if (isLoggedIn) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <main>
      <section className="sign-up-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sign-up-text">
                <h2 className="text-headline-1">Create An Account.</h2>
                <p className="text-title-2">Sign up for ChipInPool and Simplify Group Contributions with
                  Ease</p>
              </div>
            </div>
            <div className="col-md-6">

              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? `${classes.alert} ${classes["alert-success"]}` : `${classes.alert} ${classes["alert-danger"]}`
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleRegister}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form className="sign-up-form">
                    {!successful && (
                      <div>
                        <div className="sign-up-form-input">
                          <label htmlFor="firstname">First name</label>
                          <Field
                            name="firstname"
                            type="text"
                            className={
                              (errors.firstname && touched.firstname
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="firstname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="sign-up-form-input">
                          <label htmlFor="lastname">Last name</label>
                          <Field
                            name="lastname"
                            type="text"
                            className={
                              (errors.lastname && touched.lastname
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="lastname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="sign-up-form-input">
                          <label htmlFor="username">Username</label>
                          <Field
                            name="username"
                            type="text"
                            className={
                              (errors.username && touched.username
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="sign-up-form-input">
                          <label htmlFor="date_of_birth">Date Of Birth</label>
                          <Field
                            name="date_of_birth"
                            type="date"
                            className={
                              (errors.date_of_birth && touched.date_of_birth
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="date_of_birth"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="sign-up-form-input">
                          <label htmlFor="phone">Phone</label>
                          <PhoneInput
                            inputProps={{
                              name: 'phone'
                            }}
                            country={'us'}
                            onlyCountries={['us']}
                            autoFormat={true}
                            placeholder="Write your phone number here"
                            onChange={phone => setFieldValue("phone", phone)}
                            className={
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="sign-up-form-input">
                          <label htmlFor="email">Email</label>
                          <Field
                            name="email"
                            type="email"
                            className={
                              (errors.email && touched.email ? " is-invalid" : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="sign-up-form-input">
                          <label htmlFor="password" >Password</label>
                          <Field
                            name="password"
                            type="password"
                            className={
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="sign-up-form-input">
                          <label>
                            <Field
                              name="terms_conditions"
                              type="checkbox"
                              className={
                                (errors.terms_conditions && touched.terms_conditions
                                  ? " is-invalid"
                                  : "")
                              }
                            />&nbsp;&nbsp;
                            I agree to the terms and conditions.
                          </label>
                          <ErrorMessage
                            name="terms_conditions"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="sign-up-form-input">
                          <label>
                            <Field
                              name="sms_consent"
                              type="checkbox"
                            />&nbsp;&nbsp;
                            I agree to receive SMS based on my data.
                            <br></br>
                            <p>
                              By agreeing, you are giving consent to receive SMS messages at
                              the provided phone number .You can turn off SMS notifications
                              from profile settings .
                            </p>
                          </label>
                        </div>

                        <br></br>
                        <div className="">
                          <button type="submit" className="frontend_button">
                            Sign Up
                          </button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </main>

  );
}

export default Register;