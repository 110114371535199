import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";


import PoolService from "../services/pool.service";

const user = JSON.parse(localStorage.getItem("user"));


export const getPoolFinacialActivities = createAsyncThunk(
    "pool/getPoolFinacialActivities",
    async ({ id }, thunkAPI) => {
        try {
            const response = await PoolService.getPoolFinacialActivities(id);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getPoolsEvents = createAsyncThunk(
    "pool/getPoolsEvents",
    async ({ }, thunkAPI) => {
        try {
            const response = await PoolService.getPoolsEvents();
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


export const getPoolsInWhichIamJoined = createAsyncThunk(
    "pool/getPoolsInWhichIamJoined",
    async ({ userId }, thunkAPI) => {
        try {
            const response = await PoolService.getPoolsInWhichIamJoined(userId);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


export const filterPools = createAsyncThunk(
    "pool/filterPools",
    async ({ term, joinedByMe, createdByMe, isClosed, isOpened, orderBy }, thunkAPI) => {
        try {
            const response = await PoolService.filterPools(term, joinedByMe, createdByMe, isClosed, isOpened, orderBy);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const filterEvents = createAsyncThunk(
    "pool/filterEvents",
    async ({ term }, thunkAPI) => {
        try {
            const response = await PoolService.filterEvents(term);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);




const initialState = {
    newEventPool: [],
    newPool: {},
    newEvent: {},
    selectedPoolFinacialActivities: [],
    pools: [],
    events: [],
    poolSelected: {},
    eventSelected: {}
};

const poolSlice = createSlice({
    name: 'pool',
    initialState,
    reducers: {
        setNewPool(state, action) {
            state.newPool = action.payload;
        },
        setNewEventPool(state, action) {
            state.newEventPool = action.payload;
        },
        setNewEvent(state, action) {
            state.newEvent = action.payload;
        },
        setPoolSelected(state, action) {
            state.poolSelected = action.payload;
        },
        setEventSelected(state, action) {
            state.eventSelected = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPoolFinacialActivities.fulfilled, (state, action) => {
                state.selectedPoolFinacialActivities = action.payload.Activities;
            })
            .addCase(getPoolFinacialActivities.rejected, (state, action) => {
                state.selectedPoolFinacialActivities = [];
            })
            .addCase(filterPools.fulfilled, (state, action) => {
                state.pools = action.payload.Pools;
            })
            .addCase(filterPools.rejected, (state, action) => {
                state.pools = [];
            })
            .addCase(filterEvents.fulfilled, (state, action) => {
                state.events = action.payload.Events;
            })
            .addCase(filterEvents.rejected, (state, action) => {
                state.events = [];
            })

    }
});


const { reducer } = poolSlice;
export const { setNewPool, setNewEventPool, setNewEvent, setPoolSelected, setEventSelected } = poolSlice.actions
export default reducer;