import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'moment';
import { renderToString } from 'react-dom/server';
import { v4 as uuid } from 'uuid';

import AuthService from "../../services/auth.service";
import { clearMessage } from "../../slices/message";
import classNamees from "./changePasswordRequest.module.css";

import Footer from '../../components/footer/footer';
import EventBus from "../../common/eventBus";


const ChangePasswordRequest = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const searchParams = useLocation();
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);
    const { message } = useSelector((state) => state.message);
    const [step, setStep] = useState(0);
    


    const token = new URLSearchParams(searchParams.search).get("token");    

    useEffect(() => {
        dispatch(clearMessage());
        if (token) {

            AuthService.verifyForgotPasswordRequest(token).then(
                (response) => {
                    if (Object.keys(response.data.response_body.User).length > 0) {
                        setStep(0);
                    } else {
                        setStep(1);
                    }
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }

                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );

        }

    }, [dispatch]);


    const initialValues = {
        password: "",
        confirmPassword: ""
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required("This field is required!"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    });

    const handleChangePassword = (formValue) => {

        const { password } = formValue;

        AuthService.changePassword(token, password).then(
            (response) => {

                if (response.data.response_body.User) {

                    AuthService.updateForgotPasswordRequestComplete(token).then(
                        (response) => {
                            if (response.data.response_body.forgotPassword) {
                                setStep(2);
                            }
                        },
                        (error) => {
                            const _content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.response_message) ||
                                error.message ||
                                error.toString();

                            setContent(_content);

                            if (error.response && error.response.status === 401) {
                                EventBus.dispatch("logout");
                            }

                            if (error.response && error.response.status === 500) {
                                EventBus.dispatch("logout");
                            }

                        }
                    );
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }

                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );

    }


    return (
        <>
            <main>
                <section className="login-area">
                    <div className="container">
                        <div className="row">
                            <div>
                                <div className="sign-up-text">
                                    <h2 className="text-headline-1 text-center">Reset password</h2>
                                </div>
                                {
                                    (step == 1) && (
                                        <div className="alert alert-danger" role="alert">
                                            Invalid request or token expired. Please do forget password again.
                                        </div>
                                    )
                                }
                                {
                                    (step == 2) && (

                                        <div className="alert alert-success" role="alert">
                                            Password changed successfully.
                                        </div>
                                    )
                                }
                            </div>

                            <div className="d-flex justify-content-center mt-5">

                                {
                                    (step == 0) && (

                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                            onSubmit={handleChangePassword}
                                        >
                                            {({ errors, touched }) => (
                                                <Form className="sign-up-form">
                                                    <div>

                                                        <div className="sign-up-form-input">
                                                            <label htmlFor="password">Password</label>
                                                            <Field
                                                                name="password"
                                                                type="password"
                                                                className={
                                                                    (errors.password && touched.password ? " is-invalid" : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="password"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="sign-up-form-input">
                                                            <label htmlFor="confirmPassword">confirm Password</label>
                                                            <Field
                                                                name="confirmPassword"
                                                                type="password"
                                                                className={
                                                                    (errors.confirmPassword && touched.confirmPassword ? " is-invalid" : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="confirmPassword"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <br></br>
                                                        <div>
                                                            <button
                                                                type="submit"
                                                                className="frontend_button"
                                                                disabled={loading}
                                                            >
                                                                {loading && (
                                                                    <span className="spinner-border spinner-border-sm"></span>
                                                                )}
                                                                <span className="login valign-text-middle">Submit</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );

}


export default ChangePasswordRequest;