import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setProgress } from "../..//slices/loader";
import { login } from "../..//slices/auth";
import { clearMessage } from "../..//slices/message";

import classes from "./Login.module.css";


import Footer from '../..//components/footer/footer';

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  const searchParams = useLocation();
  const [successful, setSuccessful] = useState(false);
  const { progress } = useSelector((state) => state.loader);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);



  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);


  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });


  const handleLogin = (formValue) => {

    const { username, password } = formValue;
    setLoading(true);
    dispatch(setProgress(progress + 50));

    dispatch(login({ username, password }))
      .unwrap()
      .then((originalPromiseResult) => {

        const returnUrl = new URLSearchParams(location.search).get("returnUrl");
        if (returnUrl) {
          navigate(decodeURIComponent(returnUrl))
        } else {
          navigate("/dashboard")
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        setSuccessful(false);
        dispatch(setProgress(100))
      })

  }

  if (isLoggedIn) {
    return <Navigate to={"/dashboard"} />;
  }



  return (
    <main>
      <section className="login-area">
        <div className="container">
          <div className="row">
            <div>
              <div className="sign-up-text">
                <h2 className="text-headline-1 text-center">Welcome Back!</h2>
                <p className="text-title-2 mx-auto text-center">Login to your account and continue your financial collaboration journey</p>
              </div>
              {message && (
                <div className="col-4" style={{ margin: 'auto' }}>
                  <div
                    className={
                      successful ? `${classes.alert} ${classes["alert-success"]}` : `${classes.alert} ${classes["alert-danger"]}`
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-center mt-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                {({ errors, touched }) => (
                  <Form className="sign-up-form">
                    {!successful && (
                      <div>
                        <div className="sign-up-form-input">
                          <label htmlFor="username">Username</label>
                          <Field
                            name="username"
                            type="text"
                            className={
                              (errors.username && touched.username ? " is-invalid" : "")
                            }
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="sign-up-form-input">
                          <label htmlFor="password">Password</label>
                          <Field
                            name="password"
                            type="password"
                            className={
                              (errors.password && touched.password ? " is-invalid" : "")
                            }
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <br></br>
                        <div>
                          <button
                            type="submit"
                            className="frontend_button"
                            disabled={loading}
                          >
                            {loading && (
                              <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span className="login valign-text-middle">Sign In</span>
                          </button>
                        </div>
                        <div className="form-group">
                          <div className="col ">
                            <Link to={"/forgot-password"} className="text-end nav-link">
                              Lost password?
                            </Link>
                          </div>
                        </div>
                      </div>)}
                  </Form>
                )}
              </Formik>

            </div>
          </div>
        </div>
      </section>
    </main>
  );
}


export default Login;