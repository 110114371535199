import React, { useState, useEffect, useRef } from "react";
import classes from './createPool.module.css';
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { renderToString } from 'react-dom/server';


import { Navigate, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import useMediaQuery from "../../hooks/useMediaQuery";
import UserService from "../../services/user.service";

import NavigationBar from '../../components/navigation/navigation';
import { clearMessage, setMessage } from "../../slices/message";
import SinglePool from '../../components/singlePool/singlePool';
import MultiplePool from "../../components/multiplePool/multiple";
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import { setProgress } from "../../slices/loader";

const PoolTypeAndFormatSelection = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { progress } = useSelector((state) => state.loader);
    const { user: currentUser } = useSelector((state) => state.auth);
    const query = new URLSearchParams(location.search);
    const [PoolsFormatID, setPoolsFormatID] = useState(0);
    const [PoolsTypeID, setPoolsTypeID] = useState(0);
    const [BettingsPoolTypesAndFormats, setBettingsPoolTypesAndFormats] = useState([]);

    useEffect(() => {

        if (query.get('type') && query.get('formatId') && query.get('typeId')) {

            if (query.get('type') === 'Single') {

                props.props.handleStep2Click(query.get('type'));
                setPoolsFormatID(query.get('formatId'));
                setPoolsTypeID(query.get('typeId'));

            } else if (query.get('type') === 'Event Pool') {

                props.props.handleStep2Click(query.get('type'));
                setPoolsFormatID(query.get('formatId'));
                setPoolsTypeID(query.get('typeId'));
            }
        }

    }, [BettingsPoolTypesAndFormats]);

    useEffect(() => {
        dispatch(setProgress(progress + 50));

        Promise.all([
            PoolService.getBettingsPoolTypesAndFormats()
        ]).then(function (response) {
            dispatch(setProgress(100));
            if (response[0].data.response_body.BettingsPoolTypesAndFormats) {
                setBettingsPoolTypesAndFormats(response[0].data.response_body.BettingsPoolTypesAndFormats);
            }
        }).catch((error) => {
            const _content =
                (error.response &&
                    error.response.data &&
                    error.response.data.response_message) ||
                error.message ||
                error.toString();

            dispatch(setMessage(_content));
            dispatch(setProgress(100));
            if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
            }
            if (error.response && error.response.status === 500) {
                EventBus.dispatch("logout");
            }
        });

    }, []);

    const handleStep1Click = function (type, PoolsFormatID, PoolsTypeID) {
        setSearchParams(`?${new URLSearchParams({ type: type, formatId: PoolsFormatID, typeId: PoolsTypeID })}`)
        props.props.handleStep2Click(type);
        setPoolsFormatID(PoolsFormatID);
        setPoolsTypeID(PoolsTypeID);
    };


    return (
        <>
            {
                (props.props.step1) && (
                    <div className={`${classes.PoolBody}`}>
                        <h3 className="text-headline-1 text-center">Select The Type of Pool</h3>
                        <div className="pool-type-button">
                            {
                                BettingsPoolTypesAndFormats.length > 0 && (
                                    BettingsPoolTypesAndFormats.map((row, i) => {

                                        return (
                                            <a href="#" key={i} onClick={() => handleStep1Click(row.PoolsType.name, row.PoolsFormat.id, row.PoolsType.id)} >
                                                <img src="assets/img/new-pool/create-single-pool.png" alt="create-single-pool" />
                                                <span className="text-headline-2">{row.PoolsType.name}</span>
                                            </a>
                                        );

                                    })
                                )
                            }
                        </div>

                    </div>
                )
            }
            {
                (props.props.step2) && (
                    <SinglePool
                        PoolsFormatID={PoolsFormatID}
                        PoolsTypeID={PoolsTypeID}
                        poolFormRef={props.props.poolFormRef}
                    />
                )
            }
            {
                (props.props.step3) && (
                    <MultiplePool
                        PoolsFormatID={PoolsFormatID}
                        PoolsTypeID={PoolsTypeID}
                        poolFormRef={props.props.poolFormRef}
                    />
                )
            }

        </>
    );
};


const CreatePool = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const searchParams = useLocation();
    const { progress } = useSelector((state) => state.loader);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [content, setContent] = useState("");

    const poolFormRef = useRef(null);

    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);


    const handleStep1BackClick = function () {
        if (step2 == false && step1 == true) {
            navigate(-1)
        } else {
            setStep2(false);
            setStep1(true);
        }
    }

    const handleStep2Click = (type) => {

        if (type === "Single") {
            setStep2(true);
            setStep1(false);
            setStep3(false);
        }
        if (type === "Event Pool") {
            setStep2(false);
            setStep1(false);
            setStep3(true);
        }
    }

    const triggerPoolSubmit = () => {

        if (poolFormRef.current) {
            poolFormRef.current.handleSubmit();
        }
    }





    return (
        < >
            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Create Pool" }} />

                    <div className="create-new-pool-area">
                        <div className="pool-topbar d-flex justify-content-between">
                            <div className="back-button-area d-flex align-items-center">
                                <button onClick={handleStep1BackClick}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round" />
                                        <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                                <h2 className="text-title-1 m-0">Create New Pool</h2>
                            </div>
                            <div className="pool-buttons-area">
                                <button className="cancel-new-pool text-title-4" onClick={() => { navigate("/pools") }} >Cancel</button>
                                <button className="publish-new-pool text-title-4" onClick={triggerPoolSubmit} >Publish Pool</button>
                            </div>
                        </div>
                        <PoolTypeAndFormatSelection props={
                            {
                                poolFormRef: poolFormRef,
                                step1: step1,
                                step2: step2,
                                step3: step3,
                                handleStep2Click: handleStep2Click
                            }}
                        />
                    </div>
                </div>
            </main>
        </>
    );
};

export default CreatePool;
