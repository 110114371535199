import classNamees from "./pools.module.css";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link, useLocation, useSearchParams } from "react-router-dom";

import NavigationBar from "../../components/navigation/navigation";
import { setProgress } from "../../slices/loader";
import Pool from "../../components/pool/pool";
import { filterPools } from "../../slices/pool";
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";

const Pools = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()
  const urlParams = new URLSearchParams(window.location.search);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { pools } = useSelector((state) => state.pool);
  const { progress } = useSelector((state) => state.loader);


  useEffect(() => {
    dispatch(setProgress(progress + 50));

    dispatch(filterPools({
      term: urlParams.get('term') ? urlParams.get('term') : '',
      joinedByMe: urlParams.get('joinedByMe') ? urlParams.get('joinedByMe') : '',
      createdByMe: urlParams.get('createdByMe') ? urlParams.get('createdByMe') : '',
      isClosed: urlParams.get('isClosed') ? urlParams.get('isClosed') : '',
      isOpened: urlParams.get('isOpened') ? urlParams.get('isOpened') : '',
      orderBy: urlParams.get('orderBy') ? urlParams.get('orderBy') : ''
    }));

    dispatch(setProgress(100));

  }, [searchParams])

  const handleFilterTab = (tabs) => {

    let joinedByMe = '';
    let createdByMe = '';

    if (tabs == 1) {
      joinedByMe = currentUser.user.id;
    }

    if (tabs == 2) {
      createdByMe = currentUser.user.id;
    }

    if (tabs == 3) {
      joinedByMe = '';
      createdByMe = '';
    }

    setSearchParams(`?${new URLSearchParams({
      term: urlParams.get('term') ? urlParams.get('term') : '',
      joinedByMe: joinedByMe,
      createdByMe: createdByMe,
      isClosed: urlParams.get('isClosed') ? urlParams.get('isClosed') : '',
      isOpened: urlParams.get('isOpened') ? urlParams.get('isOpened') : '',
      orderBy: urlParams.get('orderBy') ? urlParams.get('orderBy') : ''
    })}`)

  }

  const handleSubFilterTab = (subTabs) => {

    let isClosed = '';
    let isOpened = '';

    if (subTabs == 1) {
      if (!urlParams.get('isClosed')) {
        isClosed = 1;
      }

    } else if (subTabs == 2) {
      if (!urlParams.get('isOpened')) {
        isOpened = 1;
      }
    }

    setSearchParams(`?${new URLSearchParams({
      term: urlParams.get('term') ? urlParams.get('term') : '',
      joinedByMe: urlParams.get('joinedByMe') ? urlParams.get('joinedByMe') : '',
      createdByMe: urlParams.get('createdByMe') ? urlParams.get('createdByMe') : '',
      isClosed: isClosed,
      isOpened: isOpened,
      orderBy: urlParams.get('orderBy') ? urlParams.get('orderBy') : ''
    })}`)
  }

  const handleSort = (order) => {

    let orderBy = 'createdAt,DESC';

    if (order == 0) {

      orderBy = 'name,ASC';

    }

    if (order == 2) {

      orderBy = 'createdAt,DESC';

    }

    if (order == 2) {

      orderBy = 'total_contributed,DESC';

    }

    setSearchParams(`?${new URLSearchParams({
      term: urlParams.get('term') ? urlParams.get('term') : '',
      joinedByMe: urlParams.get('joinedByMe') ? urlParams.get('joinedByMe') : '',
      createdByMe: urlParams.get('createdByMe') ? urlParams.get('createdByMe') : '',
      isClosed: urlParams.get('isClosed') ? urlParams.get('isClosed') : '',
      isOpened: urlParams.get('isOpened') ? urlParams.get('isOpened') : '',
      orderBy: orderBy
    })}`)


  }

  const handleSearch = (search) => {

    setSearchParams(`?${new URLSearchParams({
      term: search,
      joinedByMe: urlParams.get('joinedByMe') ? urlParams.get('joinedByMe') : '',
      createdByMe: urlParams.get('createdByMe') ? urlParams.get('createdByMe') : '',
      isClosed: urlParams.get('isClosed') ? urlParams.get('isClosed') : '',
      isOpened: urlParams.get('isOpened') ? urlParams.get('isOpened') : '',
      orderBy: urlParams.get('orderBy') ? urlParams.get('orderBy') : ''
    })}`)

  }

  const PoolFiltersSection = useCallback(() => {

    return <>
      {(currentUser) && (
        <div className="pool-filters">
          <button
            className={urlParams.get('joinedByMe') ? "selected" : ""}
            data-filter=".myJoinedPools"
            onClick={() => handleFilterTab(1)}>
            My Joined Pools
          </button>
          <button
            className={urlParams.get('createdByMe') ? "selected" : ""}
            data-filter=".myPools"
            onClick={() => handleFilterTab(2)}>
            My Pools
          </button>
          <button
            className={((!(urlParams.get('joinedByMe')) && (!urlParams.get('createdByMe')) ? "selected" : ""))}
            data-filter=".publicPools"
            onClick={() => handleFilterTab(3)}>
            All
          </button>
        </div>
      )}
      <div className="pool-filters">
        <button
          className={urlParams.get('isClosed') == '1' ? "selected" : ""}
          data-filter=".closedPools"
          onClick={() => handleSubFilterTab(1)}>
          Closed Pools
        </button>
        <button
          className={urlParams.get('isOpened') == '1' ? "selected" : ""}
          data-filter=".openPools"
          onClick={() => handleSubFilterTab(2)}>
          Open Pools
        </button>
      </div>
    </>

  }, [searchParams]);

  return (
    <>
      <main>
        <div className="cip-dashboard-body">
          <NavigationBar props={{ type: 0, pageName: "Pools" }} />

          <div className="pools-area-buttons">
            <PoolFiltersSection />
            <div className="top-bar-elements" style={{ marginRight: '1%' }}>
              <div className="search-box text-body-2">
                <input type="text" placeholder="Search here..." onKeyDown={(e) => handleSearch(e.target.value)} />
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M17.5 17.5L13.875 13.875" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </div>
            </div>

            <div className="pool-other-buttons">
              <select
                className="pool-sort-button"
                name="sortby"
                onChange={(e) => handleSort(e.target.value)}
              >
                <option value="1">Sort By: Most Recent</option>
                <option value="0">Sort By: Pool Name</option>
                <option value="2">Sort By: Most Funded</option>
              </select>
              <Link to={"/create-pool"} className="new-pool-button">
                Create New Pool
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 4.16675V15.8334"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.16669 10H15.8334"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
          </div>

          <div className="discover-pools-container">
            {pools.map((row, i) => (
              <Pool pool={row} index={i} key={i} />
            ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default Pools;
