import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'moment';
import { renderToString } from 'react-dom/server';
import { v4 as uuid } from 'uuid';

import AuthService from "../../services/auth.service";
import { clearMessage } from "../../slices/message";
import classes from "./forgotPassword.module.css";

import Footer from '../../components/footer/footer';
import EventBus from "../../common/eventBus";



const ForgotPassword = () => {

    let navigate = useNavigate();
    const searchParams = useLocation();
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);
    const { message } = useSelector((state) => state.message);
    const [successful, setSuccessful] = useState(false);

    const [step, setStep] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);


    const initialValues = {
        email: ""
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("This field is required!")
    });

    const handleForgotPassword = (formValue) => {

        const { email } = formValue;

        AuthService.createForgotPassword(email).then(
            (response) => {

                if (response.data.response_body.forgotPassword) {

                    setStep(1);

                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }

                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );
    }


    return (
        <>
            <main>
                <section className="login-area">
                    <div className="container">
                        <div className="row">
                            <div>
                                <div className="sign-up-text">
                                    <h2 className="text-headline-1 text-center">Forgot password</h2>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center mt-5">
                                {
                                    (step == 0) && (
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                            onSubmit={handleForgotPassword}
                                        >
                                            {({ errors, touched }) => (
                                                <Form className="sign-up-form">
                                                    <div>
                                                        <div className="sign-up-form-input">
                                                            <label htmlFor="email">Email</label>
                                                            <Field
                                                                name="email"
                                                                type="text"
                                                                className={
                                                                    (errors.email && touched.email ? " is-invalid" : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="email"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <br></br>
                                                        <div>
                                                            <button
                                                                type="submit"
                                                                className="frontend_button"
                                                                disabled={loading}
                                                            >
                                                                {loading && (
                                                                    <span className="spinner-border spinner-border-sm"></span>
                                                                )}
                                                                <span className="login valign-text-middle">Submit</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )
                                }
                                {
                                    (step == 1) && (
                                        <div>
                                            <h2>We have sent a change password email.</h2>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );

}


export default ForgotPassword;