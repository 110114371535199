import React, { useState, useEffect, useRef } from "react";
import classes from './createEvent.module.css';
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { renderToString } from 'react-dom/server';


import useMediaQuery from "../../hooks/useMediaQuery";
import UserService from "../../services/user.service";

import NavigationBar from '../../components/navigation/navigation';
import { clearMessage, setMessage } from "../../slices/message";
import SinglePool from '../../components/singlePool/singlePool';
import MultiplePool from "../../components/multiplePool/multiple";
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import { setNewEvent } from "../../slices/pool";
import { setProgress } from "../../slices/loader";
import { addToRoom } from "../../slices/chat"

const CreateEvent = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const poolFormRef = useRef(null);
    const { user: currentUser } = useSelector((state) => state.auth);
    const { newEvent } = useSelector((state) => state.pool);
    const [loading, setLoading] = useState(false);



    const handleEventCreate = function (formValue) {

        dispatch(setNewEvent(formValue));

        const { name, description, visibility, address, city, state, maxMembersInPool, maxPool, eventDate, website, photo } = formValue;

        setLoading(true);

        if (currentUser) {

            PoolService.addPoolsEvent(
                name,
                description,
                visibility,
                address,
                city,
                state,
                maxMembersInPool,
                maxPool,
                eventDate,
                photo,
                currentUser.user.id,
                website
            ).then(
                (response) => {
                    setLoading(false);

                    let newEvent = response.data.response_body.BettingPoolEvent
                    newEvent.conversationType = 'EVENT';
                    newEvent.PoolsMessages = [];
                    dispatch(addToRoom(newEvent))

                    navigate({
                        pathname: '/event-details',
                        search: '?id=' + response.data.response_body.BettingPoolEvent.id,
                    });

                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();


                    setLoading(false);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }

                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }
                }
            ).finally(() => {
                dispatch(setNewEvent({}));
            });

        } else {

            if (!currentUser) {
                let formatReturnUrl = encodeURIComponent(`${location.pathname}${location.search}`);
                let returnUrl = `?returnUrl=${formatReturnUrl}`;
                return <Navigate to={`/login${returnUrl}`} />;
            }
        }
    }

    const triggerPoolSubmit = () => {

        if (poolFormRef.current) {
            poolFormRef.current.handleSubmit();
        }
    }

    const handleFileChange = (e, setFieldValue) => {
        if (!e.target.files) {
            return;
        }

        UserService.fileUpload(e.target.files).then(
            (response) => {
                setLoading(false);

                setFieldValue('photo', response.data.response_body.File[0].id, false);
                setFieldValue('photoUrl', response.data.response_body.File[0].url, false);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                dispatch(setMessage(_content));


                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );

    };



    return (
        < >

            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Create Event" }} />

                    <div className="create-new-pool-area">
                        <div className="pool-topbar d-flex justify-content-between">
                            <div className="back-button-area d-flex align-items-center">
                                <button >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round" />
                                        <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                                <h2 className="text-title-1 m-0">Create New Event</h2>
                            </div>
                            <div className="pool-buttons-area">
                                <button className="cancel-new-pool text-title-4" onClick={() => { navigate("/events") }} >Cancel</button>
                                <button className="publish-new-pool text-title-4" onClick={triggerPoolSubmit}>Publish Event</button>
                            </div>
                        </div>
                    </div>
                    <div className="select-type-of-pool">
                        <div className="create-single-pool-area" style={{ display: 'block' }}>
                            <div className="pool-data-inputs">
                                <Formik
                                    initialValues={{
                                        photo: newEvent?.photo ? newEvent.photo : '',
                                        photoUrl: newEvent?.photoUrl ? newEvent.photoUrl : '',
                                        name: newEvent?.name ? newEvent.name : '',
                                        description: newEvent?.description ? newEvent.description : '',
                                        visibility: newEvent?.visibility ? newEvent.visibility : 1,
                                        address: newEvent?.address ? newEvent.address : '',
                                        city: newEvent?.city ? newEvent.city : '',
                                        state: newEvent?.state ? newEvent.state : '',
                                        maxMembersInPool: newEvent?.maxMembersInPool ? newEvent.maxMembersInPool : 0,
                                        maxPool: newEvent?.maxPool ? newEvent.maxPool : 0,
                                        eventDate: newEvent?.maxPool ? newEvent.maxPool : '',
                                        website: newEvent?.website ? newEvent.website : '',
                                    }}
                                    validationSchema={Yup.object().shape({
                                        name: Yup.string().required("This field is required!"),
                                        description: Yup.string().required("This field is required!"),
                                        visibility: Yup.string().required("This field is required!"),
                                        maxMembersInPool: Yup.string().required("This field is required!"),
                                        maxPool: Yup.string().required("This field is required!"),
                                        eventDate: Yup.string().required("This field is required!")
                                    })}
                                    onSubmit={handleEventCreate}
                                    enableReinitialize={true}
                                    innerRef={poolFormRef}
                                >
                                    {({ values, errors, touched, setFieldValue }) => (
                                        <Form>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className="pool-cover-image">
                                                            <label className="pool-cover-image-label" htmlFor="pool-cover-image">
                                                                <img src={values.photoUrl ? values.photoUrl : "assets/img/new-pool/upload-pool-cover.png"} alt="pool-cover-image" />
                                                            </label>
                                                            <input className="upload-pool-cover-image" onChange={(e) => handleFileChange(e, setFieldValue)} type="file" id="pool-cover-image" />
                                                        </div>
                                                    </div>
                                                    <div className="col-8 offset-1">
                                                        <div className="mb-3">
                                                            <label htmlFor="name" className="form-label">Event name</label>
                                                            <div>
                                                                <Field
                                                                    name="name"
                                                                    type="text"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.name && touched.name ? " is-invalid" : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="name"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="description" className="form-label">Description</label>
                                                            <div>
                                                                <Field
                                                                    name="description"
                                                                    component="textarea"
                                                                    rows={10}
                                                                    className={
                                                                        `${classes.picker}` +
                                                                        " form-control" +
                                                                        (errors.description && touched.description ? " is-invalid" : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="description"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="visibility" className="form-label">Event visibility</label>
                                                            <Field as="select" name="visibility" className={"form-control"}>
                                                                <option value="0" defaultValue={{ value: 0 }}>Private</option>
                                                                <option value="1">Public</option>
                                                            </Field>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="address" className="form-label">Event Address</label>
                                                            <div>
                                                                <Field
                                                                    name="address"
                                                                    type="text"
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="city" className="form-label">Event City</label>
                                                            <div>
                                                                <Field
                                                                    name="city"
                                                                    type="text"
                                                                    className={"form-control"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="state" className="form-label">Event State</label>
                                                            <div>
                                                                <Field
                                                                    name="state"
                                                                    type="text"
                                                                    className={"form-control"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="maxMembersInPool" className="form-label">Max Members In Pool</label>
                                                            <div>
                                                                <Field
                                                                    name="maxMembersInPool"
                                                                    type="number"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.maxMembersInPool && touched.maxMembersInPool ? " is-invalid" : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="maxMembersInPool"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3" >
                                                            <label htmlFor="maxPool" className="form-label">Max Pools</label>
                                                            <div>
                                                                <Field
                                                                    name="maxPool"
                                                                    type="number"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.maxPool && touched.maxPool ? " is-invalid" : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="maxPool"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3" >
                                                            <label htmlFor="eventDate" className="form-label">Event Date</label>
                                                            <div>
                                                                <Field
                                                                    name="eventDate"
                                                                    type="datetime-local"
                                                                    className={
                                                                        `${classes.picker}` +
                                                                        " form-control" +
                                                                        (errors.eventDate && touched.eventDate ? " is-invalid" : "")
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="eventDate"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="website" className="form-label">Website</label>
                                                            <div>
                                                                <Field
                                                                    name="website"
                                                                    type="text"
                                                                    className={" form-control"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>
                                        </Form>
                                    )}
                                </Formik>
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>);
};

export default CreateEvent;
