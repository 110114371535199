import classes from './refund.module.css';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link, useSearchParams, useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server';
import Parser from 'html-react-parser';
import Multiselect from 'multiselect-react-dropdown';
import Switch from "react-switch";
import Moment from 'moment';


import Footer from '../../components/footer/footer';
import NavigationBar from '../../components/navigation/navigation';
import { convertUTCDateToLocalDate } from '../../helpers/dateTimeHelper';
import PoolService from "../../services/pool.service";
import UserService from "../../services/user.service";
import EventBus from "../../common/eventBus";
import { clearMessage, setMessage } from "../../slices/message";
import { setProgress } from "../../slices/loader";


const Refund = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParams = useLocation();
    const { progress } = useSelector((state) => state.loader);
    const { user: currentUser } = useSelector((state) => state.auth);
    const PoolID = new URLSearchParams(searchParams.search).get("id");

    const [pool, setPool] = useState({});
    const [refundRequestStatus, setRefundRequestStatus] = useState("");
    const [refundRequests, setRefundRequests] = useState([]);


    useEffect(() => {
        
        if (currentUser) {

            dispatch(setProgress(progress + 50));

            Promise.all([
                PoolService.getPoolsById(PoolID),
                PoolService.getPoolsRefundRequests(PoolID)
            ]).then(async (response) => {
                dispatch(setProgress(100));

                if (response[0].data.response_body.Pool) {
                    setPool(response[0].data.response_body.Pool);
                }

                if (response[1].data.response_body.Requests) {
                    setRefundRequests(response[1].data.response_body.Requests);
                }

            }).catch((error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            });

        }

    }, [dispatch]);

    const handlePoolsRefundRequests = () => {
        dispatch(setProgress(progress + 50));
        PoolService.createPoolsRefundRequests(PoolID, currentUser.user.id).then(
            (response) => {
                if (response) {

                    if (response.data.response_body.Request) {

                        setRefundRequests(refundRequests.concat(response.data.response_body.Request));

                        setRefundRequestStatus('Refund request registered successfully.');
                    }
                }
                dispatch(setProgress(100));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );
    };

    if (!currentUser) {
        let formatReturnUrl = encodeURIComponent(`${searchParams.pathname}${searchParams.search}`);
        let returnUrl = `?returnUrl=${formatReturnUrl}`;
        return <Navigate to={`/login${returnUrl}`} />;
    }

    return (
        <>
            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Refund" }} />

                    <div className="pool-details-area">
                        <div className="pool-topbar d-flex justify-content-between">
                            <div className="back-button-area d-flex align-items-center">
                                <button onClick={() => navigate(-1)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                                <h2 className="text-title-1 m-0">Refund Details</h2>
                            </div>

                        </div>

                        <div className="card">
                            <div className="card-header">
                                Refund Requests
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-2' style={{ marginLeft: 'auto' }}>
                                        <button type="button" onClick={handlePoolsRefundRequests} className="btn btn-primary btn-sm">Add Refund Request</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        (refundRequestStatus) &&
                                        (<div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <strong>Success!</strong> {refundRequestStatus}
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>)
                                    }
                                </div>
                                <div className='row'>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Amount Refunded</th>
                                                    <th scope="col">Refunded</th>
                                                    <th scope="col">Comment</th>
                                                    <th scope="col">Created Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(refundRequests) &&
                                                    (refundRequests.length > 0) &&
                                                    refundRequests.map(function (nextItem, j) {
                                                        return (
                                                            <tr key={nextItem.id}>
                                                                <td>
                                                                    {
                                                                        (nextItem.User) &&
                                                                        (<Link to={"/profile?id=" + nextItem.User.id}>
                                                                            {nextItem.User.firstName + " " + nextItem.User.lastName}
                                                                        </Link>)
                                                                    }
                                                                </td>
                                                                <td>${nextItem.amount ?? 0}</td>
                                                                <td>{nextItem.isRefunded ? 'Yes' : 'No'}</td>
                                                                <td>{nextItem.commentByAdmin}</td>
                                                                <td>
                                                                    {Moment(
                                                                        convertUTCDateToLocalDate(
                                                                            new Date(nextItem.createdAt)
                                                                        )
                                                                    ).format("MMMM Do YYYY, h:mm:ss a")}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </main >

        </>);
}

export default Refund