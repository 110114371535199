import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";


import AuthService from "../services/auth.service";


const user = JSON.parse(localStorage.getItem("user"));


export const register = createAsyncThunk(
    "auth/register",
    async ({ username, email, password, firstName, lastName, date_of_birth, phone, referral }, thunkAPI) => {
        try {
            const response = await AuthService.register(username, email, password, firstName, lastName, date_of_birth, phone, referral);
            thunkAPI.dispatch(setMessage(response.data.response_message));
            return response.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


export const login = createAsyncThunk(
    "auth/login",
    async ({ username, password }, thunkAPI) => {
        try {
            const data = await AuthService.login(username, password);
            return { user: data };
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


export const logout = createAsyncThunk(
    "auth/logout",
    async () => {
        await AuthService.logout();
    }
);


export const createEmailVerificationRequest = createAsyncThunk(
    "auth/createEmailVerificationRequest",
    async ({ userId }, thunkAPI) => {
        try {
            const response = await AuthService.createEmailVerificationRequest(userId);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);




const initialState = user ?
    { isLoggedIn: true, user, loading: false, } :
    { isLoggedIn: false, user: null, loading: false, };


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(register.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoggedIn = false;
                state.loading = false;
            })
            .addCase(login.pending, (state, action) => {
                state.isLoggedIn = false;
                state.user = null;
                state.loading = true;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoggedIn = false;
                state.user = null;
                state.loading = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.user = action.payload.user;
                state.loading = false;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.isLoggedIn = false;
                state.user = null;
            })
            .addCase(createEmailVerificationRequest.fulfilled, (state, action) => {

            })
            .addCase(createEmailVerificationRequest.rejected, (state, action) => {

            })
    }
});


const { reducer } = authSlice;

export default reducer;