import React, { useState, useEffect, useRef } from "react";
import classes from './chat.module.css';
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import TimeAgo from 'react-timeago';
import EmojiPicker from 'emoji-picker-react';
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import moment from "moment";
import useMediaQuery from "../../hooks/useMediaQuery";
import { convertUTCDateToLocalDate } from '../../helpers/dateTimeHelper';
import {
    setRoomSelected,
    filterRooms,
    getPoolEventMessage,
    getBettingPoolMessage,
    createPoolsMessage,
    createPoolsEventMessage
} from "../../slices/chat";

import $ from 'jquery';

const EmojiPickerDropdown = React.forwardRef((props, ref) => {

    const handleOutsideClick = (event) => {
        if (ref && ref.current && !ref.current.contains(event.target)) {
            props.setEmojiShow(false);
        }
    }


    useEffect(() => {

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [ref, handleOutsideClick]);

    return (
        <div ref={ref} style={{ position: 'relative', left: '-7px', top: '-241px' }}>
            {(props.emojiShow) && (
                <EmojiPicker emojiStyle="google" onEmojiClick={(e) => props.handleEmoji(e)} />
            )}
        </div>
    );
});

const MyMessage = (props) => {
    return (
        <div className="message-from-user">
            <div className="message-body-container">
                <div className="message-body">
                    <p className="message-text text-body-2">
                        {props.message.message}
                    </p>
                </div>
                <p className="message-time text-body-3"><TimeAgo date={convertUTCDateToLocalDate(new Date(props.message.createdAt))} /></p>
            </div>
            <div className="sender-avatar">
                <img src="assets/img/pool-members/member-1.png" alt="sender-avatar" />
            </div>
        </div>
    );
}

const OthersMessage = (props) => {
    return (
        <div className="message-from-sender">
            <div className="sender-avatar">
                <img src="assets/img/pool-members/member-1.png" alt="sender-avatar" />
            </div>
            <div className="message-body-container">
                <div className="message-body">
                    <h5 className="text-title-2 text-white">{props.message.createdByUser.firstName + ' ' + props.message.createdByUser.lastName}</h5>
                    <p className="message-text text-body-2">
                        {props.message.message}
                    </p>
                </div>
                <p className="message-time text-body-3"><TimeAgo date={convertUTCDateToLocalDate(new Date(props.message.createdAt))} /></p>
            </div>
        </div>
    );
}

const ChatPoolDetails = (props) => {


    return (<div className="pool-chat-message-details">
        <div className="message-details-header">
            <h5 className="text-title-1">Pool Chat Info</h5>
            <button className="message-details-close" onClick={props.handleShowChatPoolDetail}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
        </div>
        <div className="message-details-information">
            <div className="message-details-thumbnail-area">
                <img src="assets/img/pool-thumbnails/pool-1.png" alt="pool-thumbnails" />
            </div>
            <h3 className="text-headline-2">{props.poolSelected.name}</h3>
            {
                ((Object.keys(props.poolSelected).length > 0)) && (props.poolSelected.status == 1) ? (
                    <span className="badge-negative" style={{ marginLeft: '5px' }}>Public</span>
                ) : (
                    <span className="badge-negative" style={{ marginLeft: '5px' }}>Private</span>
                )
            }
        </div>
        <div className="message-details-pool-members-area">
            <h5 className="text-title-1">{props.poolSelected.PoolsMembers.length} Members</h5>
            <div className="message-details-pool-members-list">
                {props.poolSelected.PoolsMembers.map((val, key) => {
                    return (
                        <div className="pool-member-item" key={key} >
                            <div className="pool-member-avatar">
                                <img src="assets/img/chats/chat-member-1.png" alt="pool-member-avatar" />
                            </div>
                            <h6 className="pool-member-name text-title-2">{val.User.firstName} {val.User.lastName}</h6>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>)
}



const Chat = ({ id, type, sendMessage }) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const messagesEndRef = React.createRef();
    const mounted = useRef();
    const emojiRef = useRef(React.createRef());
    const searchParams = useLocation();

    const { user: currentUser } = useSelector((state) => state.auth);
    const { rooms, roomSelected } = useSelector((state) => state.chat);
    const { poolSelected, eventSelected } = useSelector((state) => state.pool);

    const [messageBox, setMessageBox] = useState("");
    const [emojiShow, setEmojiShow] = useState(false);
    const [mobileViewConversation, setMobileViewConversation] = useState(false);
    const [showChatPoolDetail, setShowChatPoolDetail] = useState(false);

    useEffect(() => {

        if (id) {
            if (type === 'EVENT') {

                dispatch(getPoolEventMessage({ id: id }))
                    .unwrap()
                    .then(() => {
                        dispatch(setRoomSelected(`${id}_${type}`));
                    });

            } else if (type === 'POOL') {

                dispatch(getBettingPoolMessage({ id: id }))
                    .unwrap()
                    .then(() => {
                        dispatch(setRoomSelected(`${id}_${type}`));
                    });
            }
        }

    }, []);

    useEffect(() => {
        if (isMobile) {
            setMobileViewConversation(false);
        }
    }, [isMobile]);

    const handleEmojiDisplay = () => {
        let ToggleEmojiShow = !emojiShow;
        setEmojiShow(ToggleEmojiShow);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }

    function SaveAndSendToRoom() {

        if (currentUser) {

            if (type == "POOL") {

                dispatch(createPoolsMessage({ converationId: id, message: messageBox, userId: currentUser.user.id }))
                    .unwrap()
                    .then((originalPromiseResult) => {
                        let message = originalPromiseResult.Message;
                        sendMessage({ roomId: `${id}_POOL`, message: message });
                        setMessageBox('');
                    });

            } else if (type == "EVENT") {

                dispatch(createPoolsEventMessage({ converationId: id, message: messageBox, userId: currentUser.user.id }))
                    .unwrap()
                    .then((originalPromiseResult) => {
                        let message = originalPromiseResult.Message;
                        sendMessage({ roomId: `${id}_EVENT`, message: message });
                        setMessageBox('');
                    });
            }
        }
    }

    const handleSendMessage = (e) => {
        if (e.key === 'Enter') {

            if (messageBox) {
                SaveAndSendToRoom();
            }
        }
    }

    const handleTriggerSendMessqge = () => {
        if (messageBox) {
            SaveAndSendToRoom();
        }
    }

    const handleMessageBoxChange = (e) => {
        setMessageBox(e.target.value);
    }

    const handleEmoji = (e) => {
        setMessageBox(messageBox + (e.emoji));
    }

    const handleShowChatPoolDetail = () => {
        setShowChatPoolDetail(!showChatPoolDetail)
        if (showChatPoolDetail) {
            if ($(window).width() < 993) {
                $('.pool-chat-message-details').addClass('hidden')
                $('.pool-chat-list-container').addClass('hidden')
                $('.pool-chat-message-container').removeClass('d-none')
            }

        } else {
            if ($(window).width() < 993) {
                $('.pool-chat-message-details').removeClass('hidden')
                $('.pool-chat-message-container').addClass('d-none')
                $('.pool-chat-list-container').addClass('hidden')
            }
        }
    }

    useEffect(() => {
        if (!mounted.current) {
            scrollToBottom();
            mounted.current = true;
        } else {
            scrollToBottom();
        }
    });



    if (!currentUser) {
        return (<> <div className="row">
            <div className="alert alert-danger" role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                Please sign in to view chat
            </div>
        </div></>);
    }

    else if (rooms.length == 0) {


        return (<> <div className="row">
            <div className="alert alert-danger" role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                Please join a pool for chat to display.
            </div>
        </div></>);

    } else {

        return (<>
            <div className="pool-chat-body" style={{ width: '100%' }}>
                {roomSelected &&
                    roomSelected.conversationType == "POOL" && (
                        <div className="pool-chat-message-container" style={{ width: '100%' }}>
                            <div className="pool-chat-message-area-head">
                                <div className="d-flex align-items-center">
                                    <div className="back-button-area">
                                        <button className="p-0">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="chat-thumbnail">
                                        <img src="assets/img/chats/chat-1.png" alt="chat-thumbnail" />
                                    </div>
                                    <div className="chat-heading-area">
                                        <h3 className="text-title-1">{poolSelected && poolSelected.name}</h3>
                                        <div className="pool-members">
                                            <div className="pool-member-avatars d-flex align-items-center" style={{ flexWrap: 'wrap', gap: '5px' }}>
                                                {poolSelected &&
                                                    poolSelected.PoolsMembers.map((val, key) => {
                                                        return (
                                                            <span className="badge bg-primary" key={key} style={{ marginLeft: "2px" }}>{val.User.firstName} {val.User.lastName}</span>
                                                        )
                                                    })}
                                            </div>
                                            <div className="pool-member-text text-body-3">{poolSelected && poolSelected.PoolsMembers.length} Members</div>
                                        </div>
                                    </div>
                                </div>
                                <button className="message-details-expand" onClick={() => handleShowChatPoolDetail()}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <div className="message-area-body">
                                {(roomSelected) &&
                                    roomSelected.messages.map((val, i) =>
                                        (val.createdBy === currentUser.user.id) ?
                                            (
                                                <MyMessage key={i} message={val} />
                                            ) :
                                            (

                                                <OthersMessage key={i} message={val} />
                                            )
                                    )}

                                <div ref={messagesEndRef}></div>
                            </div>
                            {poolSelected &&
                                (Object.keys(poolSelected).length > 0) &&
                                (poolSelected.PoolsPermissions.filter(x => x.memberID == currentUser.user.id && (x.roleID == 1 || x.roleID == 2)).length > 0) && (
                                    <div className="message-sending-area">
                                        <EmojiPickerDropdown style={{ top: "-383px" }} handleEmoji={handleEmoji} ref={emojiRef.current} emojiShow={emojiShow} setEmojiShow={setEmojiShow} />
                                        <label onClick={handleEmojiDisplay}>
                                            <img src="assets/img/chats/message-attachment-label.png" alt="message-attachment-label" />

                                        </label>

                                        <div className="message-input">
                                            <input type="text" placeholder="Type your message here" value={messageBox} onKeyDown={handleSendMessage} onChange={handleMessageBoxChange}></input>
                                        </div>
                                        <div className="message-send-button">
                                            <button onClick={handleTriggerSendMessqge}>
                                                <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M22.7563 12.81C22.7656 11.9547 22.2995 11.1687 21.5465 10.7542L4.00089 1.07486C3.21409 0.629854 2.28939 0.681462 1.5525 1.18315C0.801239 1.69415 0.418837 2.9158 0.632035 3.79905L2.28939 10.6543C2.45859 11.3582 3.08888 11.8548 3.81222 11.854L13.5601 11.854C14.0601 11.8447 14.4662 12.2508 14.4568 12.7508C14.4577 13.2406 14.0592 13.6391 13.5601 13.6476L3.80376 13.6391C3.07957 13.6391 2.45013 14.1332 2.27923 14.8371L0.607499 21.7101C0.405301 22.5215 0.641341 23.3345 1.21663 23.9098C1.28432 23.9775 1.36046 24.0536 1.43745 24.112C2.1811 24.6881 3.15487 24.7634 3.98396 24.3235L21.5296 14.8489C22.2825 14.4513 22.7478 13.6645 22.7563 12.81Z" fill="#C5914B"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                )}
                        </div>
                    )}

                {roomSelected &&
                    roomSelected.conversationType == "EVENT" && (
                        <div className="pool-chat-message-container" style={{ width: '100%' }}>
                            <div className="pool-chat-message-area-head">
                                <div className="d-flex align-items-center">
                                    <div className="back-button-area">
                                        <button className="p-0">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="chat-thumbnail">
                                        <img src="assets/img/chats/chat-1.png" alt="chat-thumbnail" />
                                    </div>
                                    <div className="chat-heading-area">
                                        <h3 className="text-title-1">{eventSelected.name}</h3>
                                    </div>
                                </div>
                                <button className="message-details-expand" onClick={() => handleShowChatPoolDetail()}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <div className="message-area-body">
                                {(roomSelected)
                                    && roomSelected.messages.map((val, i) =>
                                        (val.createdBy === currentUser.user.id) ?
                                            (
                                                <MyMessage key={i} message={val} />
                                            ) :
                                            (

                                                <OthersMessage key={i} message={val} />
                                            )
                                    )}

                                <div ref={messagesEndRef}></div>
                            </div>
                            {(eventSelected) &&
                                (eventSelected.createdBy == currentUser.user.id) && (
                                    < div className="message-sending-area">
                                        <EmojiPickerDropdown style={{ top: "-383px" }} handleEmoji={handleEmoji} ref={emojiRef.current} emojiShow={emojiShow} setEmojiShow={setEmojiShow} />
                                        <label onClick={handleEmojiDisplay}>
                                            <img src="assets/img/chats/message-attachment-label.png" alt="message-attachment-label" />
                                        </label>

                                        <div className="message-input">
                                            <input type="text" placeholder="Type your message here" value={messageBox} onKeyDown={handleSendMessage} onChange={handleMessageBoxChange}></input>
                                        </div>
                                        <div className="message-send-button">
                                            <button onClick={handleTriggerSendMessqge}>
                                                <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M22.7563 12.81C22.7656 11.9547 22.2995 11.1687 21.5465 10.7542L4.00089 1.07486C3.21409 0.629854 2.28939 0.681462 1.5525 1.18315C0.801239 1.69415 0.418837 2.9158 0.632035 3.79905L2.28939 10.6543C2.45859 11.3582 3.08888 11.8548 3.81222 11.854L13.5601 11.854C14.0601 11.8447 14.4662 12.2508 14.4568 12.7508C14.4577 13.2406 14.0592 13.6391 13.5601 13.6476L3.80376 13.6391C3.07957 13.6391 2.45013 14.1332 2.27923 14.8371L0.607499 21.7101C0.405301 22.5215 0.641341 23.3345 1.21663 23.9098C1.28432 23.9775 1.36046 24.0536 1.43745 24.112C2.1811 24.6881 3.15487 24.7634 3.98396 24.3235L21.5296 14.8489C22.2825 14.4513 22.7478 13.6645 22.7563 12.81Z" fill="#C5914B"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                )}
                        </div>
                    )
                }

                {roomSelected &&
                    (roomSelected.conversationType == "POOL") &&
                    (Object.keys(poolSelected).length > 0) &&
                    (showChatPoolDetail) && (
                        <ChatPoolDetails poolSelected={poolSelected} handleShowChatPoolDetail={handleShowChatPoolDetail} />
                    )}

                {roomSelected &&
                    (roomSelected.conversationType == "EVENT") &&
                    (Object.keys(eventSelected).length > 0) &&
                    (showChatPoolDetail) && (
                        <ChatPoolDetails poolSelected={eventSelected} handleShowChatPoolDetail={handleShowChatPoolDetail} />
                    )}
            </div >
        </>);

    }
}
export default Chat;