import axios from "axios";
import "../../config";

const API_URL = global.config.API_URL + "auth/";

const register = (username, email, password, firstName, lastName, date_of_birth, phone, referral) => {
  return axios.post(API_URL + "register", {
    username,
    email,
    password,
    firstName,
    lastName,
    date_of_birth,
    phone,
    referral
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.response_body) {
        localStorage.setItem("user", JSON.stringify(response.data.response_body));
      }

      return response.data.response_body;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const createForgotPassword = (email) => {
  return axios.post(API_URL + "create-forgot-password", {
    email
  });
};

const updateForgotPasswordRequestComplete = (resetPasswordToken) => {
  return axios.post(API_URL + "update-forgot-password-request-complete", {
    resetPasswordToken
  });
};

const verifyForgotPasswordRequest = (resetPasswordToken) => {
  return axios.post(API_URL + "verify-forgot-password-request", {
    resetPasswordToken
  });
};



const changePassword = (token, password) => {
  return axios.post(API_URL + "change-password", {
    token,
    password
  });
};

const changeAccountPassword = (oldPassword, newPassword, userId) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "change-account-password", {
    oldPassword,
    newPassword,
    userId
  }, { headers });
};

const getApps = () => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.get(API_URL + "apps", { headers });
}

const createApps = (name) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "apps", {
    name
  }, { headers });
}

const createEmailVerificationRequest = (userId) => {
  let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
  return axios.post(API_URL + "user/" + userId + "/email-verification-request", { headers });
}

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  createForgotPassword,
  updateForgotPasswordRequestComplete,
  verifyForgotPasswordRequest,
  changePassword,
  changeAccountPassword,
  getApps,
  createApps,
  createEmailVerificationRequest
}

export default AuthService;
