import { createSlice } from "@reduxjs/toolkit";


const initialState = {

};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      return { message: action.payload };
    },
    clearMessage: () => {
      return { message: "" };
    },
    setNotification: (state, action) => {
      return { text: action.payload.text, type: action.payload.type };
    }
  }
});


const { reducer, actions } = messageSlice;
export const { setMessage, clearMessage, setNotification } = actions;
export default reducer;