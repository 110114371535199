import React, { useState, useEffect, useRef } from "react";
import classes from "./events.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Navigate, Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Moment from "moment";
import useMediaQuery from "../../hooks/useMediaQuery";
import NavigationBar from "../../components/navigation/navigation";
import { convertUTCDateToLocalDate } from "../../helpers/dateTimeHelper";
import EventBus from "../../common/eventBus";
import { clearMessage, setMessage } from "../../slices/message";
import { filterEvents } from "../../slices/pool";
import { setProgress } from "../../slices/loader";
import PoolService from "../../services/pool.service";
import UserService from "../../services/user.service";

const Events = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParams = new URLSearchParams(window.location.search);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { events } = useSelector((state) => state.pool);
  const { progress } = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(setProgress(progress + 50));

    dispatch(filterEvents({
      term: urlParams.get('term') ? urlParams.get('term') : ''
    }));

    dispatch(setProgress(100));

  }, [searchParams]);

  const handleEventSelection = function (event) {
    navigate({
      pathname: "/event-details",
      search: "?id=" + event.id,
    });
  };

  const handleCreateNewEvent = () => {
    navigate("/create-event");
  };

  const handleSearch = (search) => {

    setSearchParams(`?${new URLSearchParams({
      term: search
    })}`)
  }

  return (
    <>
      <main>
        <div className="cip-dashboard-body">
          <NavigationBar props={{ type: 0, pageName: "Events" }} />

          <div className="pools-area-buttons">
            <div className="pool-filters"></div>
            <div className="top-bar-elements" style={{ marginRight: '1%' }}>
              <div className="search-box text-body-2">
                <input type="text" placeholder="Search here..." onKeyDown={(e) => handleSearch(e.target.value)} />
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M17.5 17.5L13.875 13.875" stroke="#67717C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </div>
            </div>
            <div className="pool-other-buttons" >
              <button
                onClick={handleCreateNewEvent}
                className="new-pool-button"
              >
                Create New Event
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 4.16675V15.8334"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.16669 10H15.8334"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="discover-pools-container">
            {events.map((row, i) => (
              <React.Fragment key={i}>
                {(() => {
                  return (
                    <div className={`pool-card`}>
                      <div className="pool-item">
                        <div className="pool-thumbnail">
                          <img
                            src={
                              row.photo
                                ? row.photo
                                : "assets/img/pool-thumbnails/pool-2.png"
                            }
                            alt="pool-thumbnail"
                          />
                        </div>
                        <div className="d-block w-100">
                          <div className="pool-elements">
                            <div className="pool-header">
                              <div className="pool-title-badge">
                                <h3
                                  className="text-title-2 "
                                  style={{
                                    wordWrap: "break-word",
                                    inlineSize: "150px",
                                  }}
                                >
                                  <a
                                    href="#"
                                    onClick={() => handleEventSelection(row)}
                                  >
                                    {row.name}
                                  </a>
                                </h3>
                                <div
                                  className="pool-badges"
                                  style={{ display: "table-row" }}
                                >
                                  {Object.keys(row).length > 0 &&
                                    row.visibility == 0 && (
                                      <span
                                        className="badge bg-warning"
                                        style={{
                                          height: "fit-content",
                                          marginRight: "auto",
                                          marginLeft: "7px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        Private
                                      </span>
                                    )}
                                  {Object.keys(row).length > 0 &&
                                    row.visibility == 1 && (
                                      <span
                                        className="badge bg-warning"
                                        style={{
                                          height: "fit-content",
                                          marginRight: "auto",
                                          marginLeft: "7px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        Public
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="pool-members">
                              Event On:{" "}
                              {Moment(
                                convertUTCDateToLocalDate(
                                  new Date(row.eventDate)
                                )
                              ).format("MMMM Do YYYY, h:mm:ss a")}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pool-footer">
                        <div className="pool-currency d-flex justify-content-between">
                          <p style={{ fontSize: "small" }}>
                            {row.address} {row.city} {row.state} {row.state}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })()}
              </React.Fragment>
            ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default Events;
