import React, { useState, useEffect, useRef } from "react";
import classes from './multiple.module.css';
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, useFormikContext, FieldArray } from "formik";
import * as Yup from "yup";
import { Navigate, useNavigate, useLocation, Link, useSearchParams } from 'react-router-dom';
import useMediaQuery from "../../hooks/useMediaQuery";
import UserService from "../../services/user.service";
import { convertUTCDateToLocalDate } from '../../helpers/dateTimeHelper';
import { v4 as uuidv4 } from 'uuid';
import { setProgress } from "../../slices/loader";
import { setNewEventPool } from "../../slices/pool";
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import Moment from 'moment';

const MultiplePool = (props) => {
    const photoRef = useRef([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { progress } = useSelector((state) => state.loader);
    const { user: currentUser } = useSelector((state) => state.auth);
    const { newEventPool } = useSelector((state) => state.pool);
    const query = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [view, setView] = useState(0);
    const [selectedEvent, setSelectedEvent] = useState(0);
    const [message, setMessage] = useState("");

    useEffect(() => {

        if (query.get('id')) {
            if (eventList.length > 0) {
                let id = query.get('id');
                setSelectedEvent(eventList.find(x => x.id == id));
                setView(1);
            }
        }

    }, [eventList]);

    useEffect(() => {
        dispatch(setProgress(progress + 50));
        Promise.all(
            [PoolService.getPoolsEvents()])
            .then(function (response) {
                dispatch(setProgress(100));

                if (response[0].data.response_body.PoolsEvents) {
                    var list = response[0].data.response_body.PoolsEvents.map((poolEvent) => {
                        poolEvent.photo = poolEvent.photo ?
                            global.config.BASE_API_URL + '/public/' + poolEvent.photo :
                            poolEvent.photo;
                        return (poolEvent)
                    });
                    setEventList(list);

                    if (query.get('id')) {
                        let id = query.get('id');
                        setSelectedEvent(list.find(x => x.id == id));
                        setView(1);
                    }
                }

            }).catch((error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            });
    }, []);

    const handlePoolCreate = async function (formValue) {

        dispatch(setNewEventPool(formValue.paramLists))

        if (currentUser) {
            let uuid = uuidv4();
            let poolsToCreated = formValue.paramLists.map((val, index) => {

                const { photo, name, description, defaultBuy_in_amount, goal_amount, maxMembers, pickDeadline, votingDeadline, is_private } = val;
                return {
                    name: name,
                    type: props.PoolsTypeID,
                    format: props.PoolsFormatID,
                    description: description,
                    defaultBuy_in_amount: defaultBuy_in_amount,
                    goal_amount: goal_amount,
                    maxMembers: maxMembers,
                    pickDeadline: pickDeadline,
                    votingDeadline: votingDeadline,
                    status: 1,
                    createdBy: currentUser.user.id,
                    ownerID: currentUser.user.id,
                    photo_id: photo ? parseInt(photo) : null,
                    group: uuid,
                    is_private: is_private,
                    PoolsEventID: selectedEvent.id
                };
            });

            await PoolService.createEventPool(
                JSON.stringify(poolsToCreated),
                selectedEvent.id
            ).then(
                (response) => {
                    setLoading(false);

                    navigate({
                        pathname: '/event-details',
                        search: '?id=' + selectedEvent.id,
                    });


                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setMessage(_content);
                    setLoading(false);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            ).finally(() => {
                dispatch(setNewEventPool([]));
            });

        } else {
            if (!currentUser) {
                let formatReturnUrl = encodeURIComponent(`${location.pathname}${location.search}`);
                let returnUrl = `?returnUrl=${formatReturnUrl}`;
                return <Navigate to={`/login${returnUrl}`} />;
            }
        }
    }

    const handleFileChange = (e, setFieldValue, index) => {
        if (!e.target.files) {
            return;
        }
        if (currentUser) {
            setLoading(true);
            UserService.fileUpload(e.target.files).then(
                (response) => {
                    setLoading(false);
                    setFieldValue(`paramLists.${index}.photo`, response.data.response_body.File[0].id, false);
                    setFieldValue(`paramLists.${index}.photoUrl`, response.data.response_body.File[0].url, false);
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                    dispatch(setMessage(_content));


                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );
        }
    };

    const handleFileChangeTrigger = (index) => {
        photoRef.current[index].click();
    }

    const handleEventSelection = (id) => {
        setSearchParams(`?${new URLSearchParams(location.search)}&id=${id}`)
        setSelectedEvent(eventList.find(x => x.id == id));
        setView(1);
    }

    return (<>
        {(view == 0) && (
            <div className="discover-pools-container" >
                <div className="col-12">
                    <div className="row">
                        <h3 style={{ textAlign: 'center' }}>Please select one the below events</h3>
                    </div>
                    <div className="row">
                        {eventList.map((row, i) =>
                            <React.Fragment key={i}>
                                {(() => {
                                    return (<div className={`pool-card ${classes['pool-card-event']}`} style={{ background: '#efd1a9', marginLeft: '10px', marginTop: '10px' }} onClick={() => handleEventSelection(row.id)}>
                                        <div className="pool-item">
                                            <div className="pool-thumbnail">
                                                <img src={row.photo ? row.photo : "assets/img/pool-thumbnails/pool-2.png"} alt="pool-thumbnail" />
                                            </div>
                                            <div className="d-block w-100">
                                                <div className="pool-elements">
                                                    <div className="pool-header">
                                                        <div className="pool-title-badge">
                                                            <h3 className="text-title-2 " style={{ wordWrap: 'break-word', inlineSize: '150px' }}><Link>{row.name}</Link></h3>
                                                            <div className="pool-badges" style={{ display: 'table-row' }}>
                                                                {
                                                                    ((Object.keys(row).length > 0)) && (row.visibility == 0) && (
                                                                        <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Private</span>
                                                                    )
                                                                }
                                                                {
                                                                    ((Object.keys(row).length > 0)) && (row.visibility == 1) && (
                                                                        <span className="badge bg-warning" style={{ height: 'fit-content', marginRight: 'auto', marginLeft: '7px', marginTop: '5px' }}>Public</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pool-members">
                                                        Event On: {Moment(convertUTCDateToLocalDate(new Date(row.eventDate))).format('MMMM Do YYYY, h:mm:ss a')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pool-footer">
                                            <div className="pool-currency d-flex justify-content-between">
                                                <p style={{ fontSize: 'small' }}>{row.address}  {row.city}  {row.state}  {row.state} </p>
                                            </div>
                                        </div>
                                    </div>);
                                })()}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        )}
        {(view == 1) && (
            <>
                <div className="row">
                    <div className="col-2">
                        <div className="select-type-of-pool" style={{ padding: '40px' }}>
                            <div className="row">
                                <div className="row">
                                    <div className="pool-details-thumbnail">
                                        <img src={selectedEvent.photo ? selectedEvent.photo : "assets/img/pool-thumbnails/pool-details.png"} style={{ height: '200px', width: '200px' }} alt="pool-thumbnail" />
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="pool-information" style={{ textAlign: 'center' }}>
                                        <h2 className="text-headline-1 m-0">{selectedEvent.name}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-10">
                        <div>
                            {
                                (message) && (
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                )
                            }

                            <Formik
                                enableReinitialize={true}
                                initialValues={
                                    {
                                        paramLists: newEventPool.length > 0 ? newEventPool : [{
                                            photo: '',
                                            photoUrl: '',
                                            name: "",
                                            defaultBuy_in_amount: 0,
                                            goal_amount: 0,
                                            maxMembers: 0,
                                            pickDeadline: '',
                                            votingDeadline: '',
                                            is_private: 0,
                                            description: ''
                                        }]
                                    }}
                                validationSchema={Yup.object().shape({
                                    paramLists: Yup.array().of(
                                        Yup.object().shape({
                                            name: Yup.string().required("This field is required!"),
                                            defaultBuy_in_amount: Yup.string().required("This field is required!"),
                                            goal_amount: Yup.string().required("This field is required!"),
                                            maxMembers: Yup.string().required("This field is required!"),
                                            pickDeadline: Yup.string().required("This field is required!"),
                                            votingDeadline: Yup.string().required("This field is required!"),
                                            description: Yup.string().required("This field is required!")
                                        })
                                    )
                                })}
                                onSubmit={handlePoolCreate}
                                innerRef={props.poolFormRef}
                            >
                                {({ values, errors, touched, setFieldValue }) => (
                                    <Form>
                                        <FieldArray name="paramLists">
                                            {({ insert, remove, push }) => (

                                                <div className="col-12">
                                                    {values.paramLists.length > 0 &&
                                                        values.paramLists.map((pool, index) => (
                                                            <div className="row" key={index}>
                                                                <div className="select-type-of-pool" style={index > 0 ? { marginTop: '20px' } : {}}>
                                                                    {(index > 0) && (
                                                                        <button
                                                                            style={{ float: 'right' }}
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            onClick={() => remove(index)}
                                                                        >
                                                                            X
                                                                        </button>
                                                                    )}
                                                                    <div className="pool-data-inputs">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                {
                                                                                    (currentUser) && (
                                                                                        <div className="data-input-item">
                                                                                            <div className="pool-cover-image" onClick={() => handleFileChangeTrigger(index)}>
                                                                                                <label className="pool-cover-image-label">
                                                                                                    <img src={values.paramLists[index].photoUrl ? values.paramLists[index].photoUrl : "assets/img/new-pool/upload-pool-cover.png"} alt="pool-cover-image" />
                                                                                                </label>
                                                                                                <input name={`pool-cover-image.${index}`} id={`pool-cover-image.${index}`}
                                                                                                    ref={el => photoRef.current[index] = el} className="upload-pool-cover-image"
                                                                                                    onChange={(e) => handleFileChange(e, setFieldValue, index)} type="file" />
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="mb-3">
                                                                                    <label htmlFor={`paramLists.${index}.name`} className="form-label">Name</label>

                                                                                    <Field
                                                                                        name={`paramLists.${index}.name`}
                                                                                        type="text"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (
                                                                                                errors &&
                                                                                                    errors.paramLists &&
                                                                                                    errors.paramLists[index] &&
                                                                                                    errors.paramLists[index].name &&
                                                                                                    touched &&
                                                                                                    touched.paramLists &&
                                                                                                    touched.paramLists[index] &&
                                                                                                    touched.paramLists[index].name
                                                                                                    ? " is-invalid" : "")
                                                                                        }
                                                                                    />

                                                                                    <ErrorMessage
                                                                                        name={`paramLists.${index}.name`}
                                                                                        component="div"
                                                                                        className="invalid-feedback"
                                                                                    />

                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor={`paramLists.${index}.defaultBuy_in_amount`} className="form-label">Default buy in amount</label>
                                                                                    <Field
                                                                                        name={`paramLists.${index}.defaultBuy_in_amount`}
                                                                                        type="number"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (
                                                                                                errors &&
                                                                                                    errors.paramLists &&
                                                                                                    errors.paramLists[index] &&
                                                                                                    errors.paramLists[index].defaultBuy_in_amount &&
                                                                                                    touched &&
                                                                                                    touched.paramLists &&
                                                                                                    touched.paramLists[index] &&
                                                                                                    touched.paramLists[index].defaultBuy_in_amount
                                                                                                    ? " is-invalid" : "")
                                                                                        }
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`paramLists.${index}.defaultBuy_in_amount`}
                                                                                        component="div"
                                                                                        className="invalid-feedback"
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor={`paramLists.${index}.goal_amount`} className="form-label">Goal amount</label>
                                                                                    <Field
                                                                                        name={`paramLists.${index}.goal_amount`}
                                                                                        type="number"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (
                                                                                                errors &&
                                                                                                    errors.paramLists &&
                                                                                                    errors.paramLists[index] &&
                                                                                                    errors.paramLists[index].goal_amount &&
                                                                                                    touched &&
                                                                                                    touched.paramLists &&
                                                                                                    touched.paramLists[index] &&
                                                                                                    touched.paramLists[index].goal_amount
                                                                                                    ? " is-invalid" : "")
                                                                                        }
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`paramLists.${index}.goal_amount`}
                                                                                        component="div"
                                                                                        className="invalid-feedback"
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor={`paramLists.${index}.maxMembers`} className="form-label">Max allowed members</label>
                                                                                    <Field
                                                                                        name={`paramLists.${index}.maxMembers`}
                                                                                        type="number"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (
                                                                                                errors &&
                                                                                                    errors.paramLists &&
                                                                                                    errors.paramLists[index] &&
                                                                                                    errors.paramLists[index].maxMembers &&
                                                                                                    touched &&
                                                                                                    touched.paramLists &&
                                                                                                    touched.paramLists[index] &&
                                                                                                    touched.paramLists[index].maxMembers
                                                                                                    ? " is-invalid" : "")
                                                                                        }
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`paramLists.${index}.maxMembers`}
                                                                                        component="div"
                                                                                        className="invalid-feedback"
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor={`paramLists.${index}.pickDeadline`} className="form-label">Pick deadline</label>
                                                                                    <Field
                                                                                        name={`paramLists.${index}.pickDeadline`}
                                                                                        type="date"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (
                                                                                                errors &&
                                                                                                    errors.paramLists &&
                                                                                                    errors.paramLists[index] &&
                                                                                                    errors.paramLists[index].pickDeadline &&
                                                                                                    touched &&
                                                                                                    touched.paramLists &&
                                                                                                    touched.paramLists[index] &&
                                                                                                    touched.paramLists[index].pickDeadline
                                                                                                    ? " is-invalid" : "")
                                                                                        }
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`paramLists.${index}.pickDeadline`}
                                                                                        component="div"
                                                                                        className="invalid-feedback"
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor={`paramLists.${index}.votingDeadline`} className="form-label">Voting deadline</label>
                                                                                    <Field
                                                                                        name={`paramLists.${index}.votingDeadline`}
                                                                                        type="date"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (
                                                                                                errors &&
                                                                                                    errors.paramLists &&
                                                                                                    errors.paramLists[index] &&
                                                                                                    errors.paramLists[index].votingDeadline &&
                                                                                                    touched &&
                                                                                                    touched.paramLists &&
                                                                                                    touched.paramLists[index] &&
                                                                                                    touched.paramLists[index].votingDeadline
                                                                                                    ? " is-invalid" : "")
                                                                                        }
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`paramLists.${index}.votingDeadline`}
                                                                                        component="div"
                                                                                        className="invalid-feedback"
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor={`paramLists.${index}.is_private`} className="form-label">Pool visibility</label>
                                                                                    <Field as="select" name={`paramLists.${index}.is_private`} className={"form-control"}>
                                                                                        <option value="0" defaultValue={{ value: 0 }}>Private</option>
                                                                                        <option value="1">Public</option>
                                                                                    </Field>
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor={`paramLists.${index}.description`} className="form-label">Description</label>
                                                                                    <Field
                                                                                        name={`paramLists.${index}.description`}
                                                                                        component="textarea"
                                                                                        rows={10}
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (
                                                                                                errors &&
                                                                                                    errors.paramLists &&
                                                                                                    errors.paramLists[index] &&
                                                                                                    errors.paramLists[index].description &&
                                                                                                    touched &&
                                                                                                    touched.paramLists &&
                                                                                                    touched.paramLists[index] &&
                                                                                                    touched.paramLists[index].description
                                                                                                    ? " is-invalid" : "")
                                                                                        }
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`paramLists.${index}.description`}
                                                                                        component="div"
                                                                                        className="invalid-feedback"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    <br></br>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => push({
                                                            photo: '',
                                                            name: "",
                                                            defaultBuy_in_amount: 0,
                                                            goal_amount: 0,
                                                            maxMembers: 0,
                                                            pickDeadline: '',
                                                            votingDeadline: '',
                                                            is_private: 0,
                                                            description: '',
                                                        })}
                                                    >
                                                        {" "}
                                                        +{" "}
                                                    </button>
                                                </div>

                                            )}
                                        </FieldArray>
                                    </Form>
                                )}
                            </Formik>
                            <br></br>
                        </div>
                    </div>
                </div>
            </>
        )}
    </>);
}

export default MultiplePool;