import classes from './event.module.css';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link, useSearchParams, useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server';
import Parser from 'html-react-parser';
import Multiselect from 'multiselect-react-dropdown';
import Switch from "react-switch";
import Moment from 'moment';


import Footer from '../../components/footer/footer';
import NavigationBar from '../../components/navigation/navigation';
import { convertUTCDateToLocalDate } from '../../helpers/dateTimeHelper';
import PoolService from "../../services/pool.service";
import UserService from "../../services/user.service";
import EventBus from "../../common/eventBus";
import { clearMessage, setMessage } from "../../slices/message";
import { setProgress } from "../../slices/loader";

const EventPayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParams = useLocation();
    const { progress } = useSelector((state) => state.loader);
    const { user: currentUser } = useSelector((state) => state.auth);
    const EventID = new URLSearchParams(searchParams.search).get("eventId");
    const [event, setEvent] = useState({});
    const [votes, setVotes] = useState([]);
    const [winnerMultiSelect, setWinnerMultiSelect] = useState([]);
    const [winnerFinalMultiSelect, setWinnerFinalMultiSelect] = useState([]);
    const [selectedWinners, setSelectedWinners] = useState([]);
    const [selectedFinalWinners, setSelectedFinalWinners] = useState([]);
    const [winners, setWinners] = useState([]);
    const [finalWinners, setFinalWinners] = useState([]);
    const [validatePayoutAmount, setValidatePayoutAmount] = useState("");
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const [pools, setPools] = useState([]);

    const POOl_SERVICE_CHARGE_PERCENT_UPTO_99 = global.config.POOl_SERVICE_CHARGE_PERCENT_UPTO_99;
    const POOl_SERVICE_CHARGE_PERCENT_BETWEEN_100_AND_499 = global.config.POOl_SERVICE_CHARGE_PERCENT_BETWEEN_100_AND_499;
    const POOl_SERVICE_CHARGE_PERCENT_MORE_THAN_499 = global.config.POOl_SERVICE_CHARGE_PERCENT_MORE_THAN_499;

    useEffect(() => {
        dispatch(clearMessage());

        if (EventID) {

            dispatch(setProgress(progress + 50));
            Promise.all(currentUser ? [
                PoolService.getPoolsEventById(EventID),
                PoolService.getPoolsByEventId(EventID),
                UserService.getSubscription(currentUser.user.id)
            ] : [
                PoolService.getPoolsEventById(EventID),
                PoolService.getPoolsByEventId(EventID)
            ]).then((response) => {
                dispatch(setProgress(100));

                if (response[0].data.response_body.BettingPoolEvent) {
                    setEvent(response[0].data.response_body.BettingPoolEvent)
                    response[0].data.response_body.BettingPoolEvent.photo = response[0].data.response_body.BettingPoolEvent.photo ?
                        global.config.BASE_API_URL + '/public/' + response[0].data.response_body.BettingPoolEvent.photo :
                        response[0].data.response_body.BettingPoolEvent.photo;
                }

                if (response[1].data.response_body.Poolss) {

                    setPools(response[1].data.response_body.Poolss);
                    let pools = response[1].data.response_body.Poolss;
                    let members = [];
                    pools.forEach((pool) => {

                        let poolMembers = [];

                        poolMembers = pool.PoolsMembers.filter(x => x.memberID != x.ownerID).map(function (element) {
                            return { name: (element.User.firstName + ' ' + element.User.lastName + "- (" + pool.name + ") "), poolId: pool.id, id: element.User.id }
                        });

                        members.push(...poolMembers);
                    });

                    /* members = [
                         ...new Map(
                             members.map(x => [x.id, x])
                         ).values()
                     ]*/

                    setWinnerMultiSelect(members);
                }

                if (currentUser && response[2].data.response_body.Subscription) {
                    setUserSubscriptions(response[2].data.response_body.Subscription);
                }

            }).catch((error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            });

        }

    }, [dispatch]);

    useEffect(() => {

        let t_winners = [];
        let t_votes = [];
        let t_winnerFinalMultiSelect = [];
        let t_finalWinners = [];

        (async function () {

            for (const pool of pools) {

                let response = await Promise.all([
                    PoolService.getWinnersByPools(pool.id),
                    PoolService.haveVotedForPool(pool.id, currentUser.user.id),
                    PoolService.getWinnersByPools(pool.id),
                    PoolService.getFinalWinnerByPools(pool.id)
                ]).catch((error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    dispatch(setMessage(_content));
                    dispatch(setProgress(100));
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }
                });

                if (response[0].data.response_body.winners) {
                    let win = response[0].data.response_body.winners.map((winner) => { winner.isYes = false; return winner; });
                    t_winners = t_winners.concat(win);
                }

                if (response[1].data.response_body.Votes) {
                    t_votes = t_votes.concat(response[1].data.response_body.Votes);
                }

                if (response[2].data.response_body.winners) {

                    let fWin = response[2].data.response_body.winners.map(function (element) {
                        return { name: (element.User.firstName + ' ' + element.User.lastName + "- (" + pool.name + ") "), poolId: element.PoolsID, id: element.User.id }
                    });

                    t_winnerFinalMultiSelect = t_winnerFinalMultiSelect.concat(fWin);
                }

                if (response[3].data.response_body.Winners) {
                    t_finalWinners = t_finalWinners.concat(response[3].data.response_body.Winners);
                }

            }

            setWinners(t_winners);
            setVotes(t_votes);
            setWinnerFinalMultiSelect(t_winnerFinalMultiSelect);
            setFinalWinners(t_finalWinners);
        })();


    }, [pools]);

    const onAddVote = (winnerId, PoolID, isYes) => {

        dispatch(setProgress(progress + 50));
        PoolService.addVoteForWinner(PoolID, winnerId, isYes, currentUser.user.id).then(
            (response) => {

                setWinners(response.data.response_body.Votes)
                dispatch(setProgress(100));

                dispatch(setProgress(progress + 50));
                PoolService.haveVotedForPool(PoolID, currentUser.user.id).then(
                    (response1) => {
                        if (response1.data.response_body.Votes) {
                            setVotes(response1.data.response_body.Votes);
                        }
                        dispatch(setProgress(100));
                    },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.response_message) ||
                            error.message ||
                            error.toString();

                        dispatch(setMessage(_content));
                        dispatch(setProgress(100));

                        if (error.response && error.response.status === 401) {
                            EventBus.dispatch("logout");
                        }
                        if (error.response && error.response.status === 500) {
                            EventBus.dispatch("logout");
                        }

                    }
                );
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );

    }

    const handleVoteYesOrNO = (nextItem, e) => {
        let nextWinners = [...winners];
        let winner = winners.find(x => x.id == nextItem.id);
        winner.isYes = e;
        let index = winners.findIndex(x => x.id == nextItem.id);
        nextWinners[index] = winner;
        setWinners(nextWinners);
    }

    const onWinnerSelect = (selectedList, selectedItem) => {
        selectedItem.amount = 0;
        const winners = [...selectedWinners, selectedItem];
        setSelectedWinners(winners);
    }

    const onWinnerRemove = (selectedList, removedItem) => {
        const updatedWinner = selectedWinners.filter((win) => { return win.id != removedItem.id });
        setSelectedWinners(updatedWinner);
    }

    const handleSaveWinnders = () => {

        let totalContributed = 0;

        pools.forEach((pool, pIndex) => {
            pool.PoolsMembers.forEach((poolMember, index) => {
                totalContributed += parseFloat(poolMember.contributed);
            });
        });

        let fieldsFailedValidation = 0;

        selectedWinners.forEach((winner, index) => {

            if (parseFloat(winner.amount) == 0) {
                setValidatePayoutAmount("Payout amount for any member cannot be zero");
                fieldsFailedValidation = fieldsFailedValidation + 1;
                return;
            }

            if (parseFloat(winner.amount) > parseFloat(totalContributed)) {
                setValidatePayoutAmount("Payout amount for any member cannot be greater than total amount contributed for the pool.");
                fieldsFailedValidation = fieldsFailedValidation + 1;
                return;
            }

        });

        if (fieldsFailedValidation > 0) {
            return;
        }

        setValidatePayoutAmount("");
        selectedWinners.forEach((winner, index) => {

            dispatch(setProgress(progress + 50));

            PoolService.addWinnerForPool(winner.poolId, winner.id, currentUser.user.id, calculateAmountWithDeductedServiceFee(parseFloat(winner.amount))).then(
                (response) => {

                    setWinners(response.data.response_body.Winners.map((winner) => { winner.isYes = false; return winner; }));
                    dispatch(setProgress(100));
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    dispatch(setMessage(_content));
                    dispatch(setProgress(100));

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );

        });
    }

    const onFinalWinnerSelect = (selectedList, selectedItem) => {
        const winners = [...selectedFinalWinners, selectedItem];
        setSelectedFinalWinners(winners);
    }

    const onFinalWinnerRemove = (selectedList, removedItem) => {
        const updatedWinner = selectedFinalWinners.filter((win) => { return win.id != removedItem.id });
        setSelectedFinalWinners(updatedWinner);
    }

    const onSaveFinalWinner = () => {
        if (selectedFinalWinners.length > 0) {
            selectedFinalWinners.forEach(element => {

                dispatch(setProgress(progress + 50));
                PoolService.updateFinalWinner(element.poolId, element.id).then(
                    (response) => {

                        setFinalWinners(response.data.response_body.Winners);
                        dispatch(setProgress(100));
                    },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.response_message) ||
                            error.message ||
                            error.toString();

                        dispatch(setMessage(_content));
                        dispatch(setProgress(100));

                        if (error.response && error.response.status === 401) {
                            EventBus.dispatch("logout");
                        }
                        if (error.response && error.response.status === 500) {
                            EventBus.dispatch("logout");
                        }

                    }
                );
            });
        }
    }

    const calculateServiceFee = (amount) => {
        let actualServiceFee = 1;

        {/*
        if (parseFloat(amount) <= 99) {
            actualServiceFee = POOl_SERVICE_CHARGE_PERCENT_UPTO_99;
        }

        if (parseFloat(amount) >= 100 && parseFloat(amount) <= 499) {
            actualServiceFee = POOl_SERVICE_CHARGE_PERCENT_BETWEEN_100_AND_499;
        }

        if (parseFloat(amount) >= 500) {
            actualServiceFee = POOl_SERVICE_CHARGE_PERCENT_MORE_THAN_499;
        }
        */}

        return actualServiceFee;
    }

    const calculateAmountWithDeductedServiceFee = (amount) => {
        let sfee = 0.00;
        if (userSubscriptions.PoolsPlans.type == 'FREE') {
            sfee = calculateServiceFee(parseFloat(amount));
        }
        let serviceFee = (parseFloat(sfee) / 100) * parseFloat(amount);
        return ((parseFloat(amount) - serviceFee));
    }



    return (
        < >

            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Event Payout" }} />

                    <div className="pool-details-area">
                        <div className="pool-topbar d-flex justify-content-between">
                            <div className="back-button-area d-flex align-items-center">
                                <button onClick={() => navigate(-1)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                                <h2 className="text-title-1 m-0">Event Details</h2>
                            </div>

                        </div>
                        <div className="col-md-12">
                            <h2>Details</h2>
                            <div className="card">
                                <div className="card-body">
                                    {
                                        (Object.keys(event).length > 0)
                                        &&
                                        (

                                            <div className="pool-data-container">
                                                <div className="pool-data-item">
                                                    <p className="text-title-3">Visibility</p>
                                                    <p><span className="badge-negative">{event.visibility == 1 ? "Public" : "Private"}</span></p>
                                                </div>
                                                <div className="pool-data-item">
                                                    <p className="text-title-3">Description</p>
                                                    <p className="text-body-2">{event.description}</p>
                                                </div>
                                                <div className="pool-data-item">
                                                    <p className="text-title-3">Address</p>
                                                    <p className="text-body-2">{event.address}  {event.city}  {event.state}  {event.state}</p>
                                                </div>
                                                <div className="pool-data-item">
                                                    <p className="text-title-3">Max Members In Pool Allowed</p>
                                                    <p className="text-body-2">{event.maxMembersInPool}</p>
                                                </div>
                                                <div className="pool-data-item">
                                                    <p className="text-title-3">Max Pools Allowed</p>
                                                    <p className="text-body-2">{event.maxPool}</p>
                                                </div>
                                                <div className="pool-data-item">
                                                    <p className="text-title-3">Event Date</p>
                                                    <p className="text-body-2">{Moment(convertUTCDateToLocalDate(new Date(event.eventDate))).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                                </div>
                                            </div>

                                        )
                                    }
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            {
                                ((Object.keys(event).length > 0))
                                && (currentUser)
                                && (event.createdBy == currentUser.user.id)
                                && (finalWinners.length == 0) && (
                                    <>
                                        <h2> Add  Payouts</h2>
                                        <div className="card">
                                            <div className="card-body">
                                                <Multiselect
                                                    options={winnerMultiSelect} // Options to display in the dropdown
                                                    onSelect={onWinnerSelect}
                                                    onRemove={onWinnerRemove} // Preselected value to persist in dropdown                                                                          
                                                    displayValue="name" // Property name to display in the dropdown options
                                                />
                                                <br></br>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    {
                                                        (selectedWinners) && (selectedWinners.length > 0) && selectedWinners.map(function (nextItem, j) {
                                                            return (
                                                                <div key={nextItem.id} style={{ marginLeft: '50px' }}>
                                                                    <div className='row'>
                                                                        <label>{nextItem.name}</label>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <input type='number' min="0.00" step="0.05" className='form-control' style={{ width: '100px' }} onChange={(e) => nextItem.amount = e.target.value} defaultValue={nextItem.amount} />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                </div>
                                                <br></br>
                                                {
                                                    (validatePayoutAmount) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {validatePayoutAmount}
                                                        </div>
                                                    )
                                                }

                                                <br></br>
                                                <button className="btn btn-primary col-md-2" style={{ float: 'right' }} onClick={handleSaveWinnders} > Submit</button>

                                            </div>
                                        </div>
                                    </>
                                )}
                            <br></br>
                            {(winners.length > 0) && (
                                <>
                                    <h2>Payouts</h2>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="alert alert-info" role="alert">
                                                Vote on who to be choosen for payouts
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                {(winners) && (winners.length > 0) && winners.map((nextItem, j) =>

                                                    <div key={nextItem.id} style={{ marginLeft: '50px', width: '250px' }} className="card">
                                                        <h5 className="card-header">Vote</h5>
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <label>{nextItem.User.firstName + ' ' + nextItem.User.lastName + "- (" + nextItem.Pools.name + ") "}</label>
                                                            </div>
                                                            <div className='row center'>
                                                                <label>Votes (Yes): {nextItem.isYesCount ? nextItem.isYesCount : 0}</label>
                                                                <label>Votes (No): {nextItem.isNoCount ? nextItem.isNoCount : 0}</label>
                                                            </div>
                                                            <div className='row center'>
                                                                <label>Amount: ${nextItem.amount}</label>
                                                            </div>
                                                            <div className='row center'>

                                                                {(votes) && (votes.length == 0) && (
                                                                    <div >
                                                                        <h3 style={{ textAlign: 'center' }}>Vote (no/yes)</h3>

                                                                        <div className='row'>
                                                                            <div className='col'><h3>No</h3></div>
                                                                            <div className='col'><Switch onChange={(e) => handleVoteYesOrNO(nextItem, e)} checked={nextItem.isYes} /></div>
                                                                            <div className='col'><h3>Yes</h3></div>
                                                                        </div>

                                                                        <br></br>

                                                                        <button className="btn btn-primary" style={{ float: 'right' }} onClick={() => onAddVote(nextItem.winnerId, nextItem.Pools.id, nextItem.isYes)} > Add</button>

                                                                    </div>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <br></br>
                            <br></br>
                            <h2>Final Payouts</h2>
                            <div className="card">
                                <div className="card-body">

                                    <div className="alert alert-info" role="alert">
                                        Final payout will show here once voting deadline has passed.
                                    </div>
                                    {
                                        ((Object.keys(event).length > 0))
                                        && (currentUser)
                                        && (event.createdBy == currentUser.user.id)
                                        && (finalWinners.length == 0) && (
                                            <div>
                                                <Multiselect
                                                    options={winnerFinalMultiSelect} // Options to display in the dropdown
                                                    onSelect={onFinalWinnerSelect}
                                                    onRemove={onFinalWinnerRemove}
                                                    displayValue="name" // Property name to display in the dropdown options
                                                />
                                                <br></br>

                                                <button className="btn btn-primary" onClick={onSaveFinalWinner}>Submit</button>

                                            </div>
                                        )
                                    }
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {
                                            (finalWinners) && (finalWinners.length > 0) && finalWinners.map(function (nextItem, j) {
                                                return (
                                                    <div key={nextItem.id} className='card' style={{ marginLeft: '50px', width: '200px' }}>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                <label>{nextItem.User.firstName} {nextItem.User.lastName}</label>
                                                            </div>
                                                            <div className='row center'>
                                                                <label>Votes (Yes): {nextItem.isYesCount ? nextItem.isYesCount : 0}</label>
                                                                <label>Votes (No): {nextItem.isNoCount ? nextItem.isNoCount : 0}</label>
                                                            </div>
                                                            <div className='row'>
                                                                <label>${nextItem.amount}</label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div >
            </main >
        </>
    )
}

export default EventPayout;