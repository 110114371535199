import classes from './profileView.module.css';
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Navigate, useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import Footer from '../../components/footer/footer';
import NavigationBar from '../../components/navigation/navigation';
import moment from "moment";
import UserService from "../../services/user.service";
import PoolService from "../../services/pool.service";
import AuthService from "../../services/auth.service";

import EventBus from "../../common/eventBus";
import { clearMessage, setMessage } from "../../slices/message";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { renderToString } from 'react-dom/server';
import { setProgress } from "../../slices/loader";

const ProfileView = () => {
    const dispatch = useDispatch();
    const [photoUrl, setPhotoUrl] = useState("");
    const { progress } = useSelector((state) => state.loader);
    const searchParams = useLocation();
    const userId = new URLSearchParams(searchParams.search).get("id");
    const { user: currentUser } = useSelector((state) => state.auth);
    const [socialMediaInitialValues, setSocialMediaInitialValues] = useState({
        paramLists: [
            {
                link: '',
                social_media: 'Twitter'
            },
            {
                link: '',
                social_media: 'Facebook'
            },
            {
                link: '',
                social_media: 'Youtube'
            },
            {
                link: '',
                social_media: 'Vimeo'
            },
            {
                link: '',
                social_media: 'Instagram'
            },
            {
                link: '',
                social_media: 'LinkedIn'
            },
            {
                link: '',
                social_media: 'Pinterest'
            }
        ]
    });
    const [accountDetails, setAccountDetails] = useState({
        firstName: "",
        lastName: ""
    });

    useEffect(() => {
        dispatch(clearMessage());

        dispatch(setProgress(progress + 50));

        Promise.all(
            [
                UserService.getAccountByUserId(),
                UserService.getSocialMediaLinksByUserId(userId)
            ])
            .then((response) => {

                if (response[0].data.response_body.Account) {
                    const user = response[0].data.response_body.Account;
                    if (user) {
                        setAccountDetails({ firstName: user.firstName, lastName: user.lastName });
                    }
                    if (user.photo) {
                        setPhotoUrl(user.photo);
                    }
                }

                if (response[1].data.response_body.SocialMedia) {
                    let socialMedia = response[1].data.response_body.SocialMedia;
                    if (socialMedia.length > 0) {
                        setSocialMediaInitialValues({ paramLists: response[1].data.response_body.SocialMedia });
                    }
                }

                dispatch(setProgress(100));

            }).catch((error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            });

    }, [dispatch]);


    if (!currentUser) {
        let formatReturnUrl = encodeURIComponent(`${searchParams.pathname}${searchParams.search}`);
        let returnUrl = `?returnUrl=${formatReturnUrl}`;
        return <Navigate to={`/login${returnUrl}`} />;
    }

    return (
        <>
            
            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Profile" }} />


                    <div className="support-tab-buttons-area">
                        <ul className="support-tab-buttons-container nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active support-tab-buttons"
                                    data-bs-toggle="pill" data-bs-target="#pills-email-us" type="button" role="tab"
                                    aria-controls="pills-email-us" aria-selected="true">Personel</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link support-tab-buttons" data-bs-toggle="pill"
                                    data-bs-target="#pills-socialmedia-tab" type="button" role="tab" aria-controls="pills-socialmedia"
                                    aria-selected="false">Social media</button>
                            </li>
                        </ul>
                    </div>

                    <div className="tab-content support-div-container" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-email-us" role="tabpanel"
                            aria-labelledby="support-email-us">
                            <div className='account-info-container'>
                                <div className="account-info-user-avatar">
                                    <div className={`${classes.container_image}`}>
                                        <img src={photoUrl ? photoUrl : "assets/img/user.png"} alt="user-avatar" />
                                    </div>
                                </div>
                                {(accountDetails) && (
                                    <div className='account-info-fields'>
                                        <div className='col-md-12'>
                                            <div className="account-info-field-item">
                                                <label className='text-title-3' htmlFor="firstName">First Name</label>
                                                <div>
                                                    <label>{accountDetails.firstName}</label>
                                                </div>
                                            </div>
                                            <div className="account-info-field-item">
                                                <label className='text-title-3' htmlFor="lastName">Last Name</label>
                                                <div>
                                                    <label>{accountDetails.lastName}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="pills-socialmedia-tab" role="tabpanel">
                            <div className='account-info-container'>
                                <div className='col-12'>
                                    {socialMediaInitialValues.paramLists.length > 0 &&
                                        socialMediaInitialValues.paramLists.map((social, index) => (
                                            <div className="row" key={index}>
                                                <div className="account-info-field-item">
                                                    <label className='text-title-3'>{social.social_media}</label>
                                                    <a href={social.link}>{social.link}</a>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    );
}

export default ProfileView;