import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import messageReducer from './slices/message';
import chatReducer from './slices/chat';
import loaderReducer from './slices/loader';
import generalReducer from './slices/general';
import poolReducer from './slices/pool';
import userReducer from './slices/user';

const reducer = {
    auth: authReducer,
    message: messageReducer,
    chat: chatReducer,
    loader: loaderReducer,
    general: generalReducer,
    pool: poolReducer,
    user: userReducer
};

export const store = configureStore({
    reducer: reducer,
    devTools: true
});

