import classes from './notifications.module.css';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import moment from 'moment';

import PoolService from "../../services/pool.service";

import Footer from '../../components/footer/footer';
import NavigationBar from '../../components/navigation/navigation';
import EventBus from "../../common/eventBus";




const Notifications = () => {
    const searchParams = useLocation();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [userNotifications, setUserNotifications] = useState([]);


    function utcToLocal(utcdateTime) {
        var localDateTime = moment(utcdateTime).local();
        return localDateTime
    }

    useEffect(() => {
        if (currentUser) {

            PoolService.getNotificationsbyUserId(currentUser.user.id).then(
                (response) => {

                    if (response.data.response_body.Notifications.length > 0) {
                        setUserNotifications(response.data.response_body.Notifications);
                    }
                },
                (error) => {

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }

                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );

        }
    }, [currentUser]);


    const handleDeleteNotificationById = (Id) => {

        PoolService.deleteNotificationById(Id).then(
            (response) => {
                if (response.data.response_body.Notification == 1) {
                    setUserNotifications(userNotifications.filter(x => x.id != Id))
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );
    }


    if (!currentUser) {
        let formatReturnUrl = encodeURIComponent(`${searchParams.pathname}${searchParams.search}`);
        let returnUrl = `?returnUrl=${formatReturnUrl}`;
        return <Navigate to={`/login${returnUrl}`} />;
    }

    return (
        <>
          
            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Notifications" }} />


                    <div className="col-md-12">
                        <div className="row">
                            <div className="card card-container" style={{ minHeight: '500px' }}>
                                <br></br>

                                {
                                    ((userNotifications === undefined) || (userNotifications.length == 0)) && (
                                        <div>
                                            <h4>You do not have any notifications yet.</h4>
                                        </div>
                                    )
                                }
                                {userNotifications?.map((item, index) => {
                                    return (
                                        <div className="alert alert-warning alert-dismissible fade show" role="alert" key={index}>
                                            {item.message}
                                            <TimeAgo date={utcToLocal(new Date(item.createdAt))} style={{ float: "right" }} />
                                            <button type="button" className="btn-close" onClick={() => handleDeleteNotificationById(item.id)} ></button>
                                        </div>)
                                })}

                            </div>
                        </div>
                    </div>


                </div>
            </main>
        </>
    );
};

export default Notifications;