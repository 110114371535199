import classes from './payout.module.css';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link, useSearchParams, useLocation } from "react-router-dom";
import { renderToString } from 'react-dom/server';
import Parser from 'html-react-parser';
import Multiselect from 'multiselect-react-dropdown';
import Switch from "react-switch";
import Moment from 'moment';


import Footer from '../../components/footer/footer';
import NavigationBar from '../../components/navigation/navigation';
import { convertUTCDateToLocalDate } from '../../helpers/dateTimeHelper';
import PoolService from "../../services/pool.service";
import UserService from "../../services/user.service";
import EventBus from "../../common/eventBus";
import { clearMessage, setMessage } from "../../slices/message";
import { setProgress } from "../../slices/loader";


const Payout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParams = useLocation();
    const { progress } = useSelector((state) => state.loader);
    const { user: currentUser } = useSelector((state) => state.auth);


    const PoolID = new URLSearchParams(searchParams.search).get("id");


    const [votes, setVotes] = useState([]);
    const [pool, setPool] = useState({});
    const [winnerMultiSelect, setWinnerMultiSelect] = useState([]);
    const [winnerFinalMultiSelect, setWinnerFinalMultiSelect] = useState([]);
    const [selectedWinners, setSelectedWinners] = useState([]);
    const [selectedFinalWinners, setSelectedFinalWinners] = useState([]);
    const [winners, setWinners] = useState([]);
    const [finalWinners, setFinalWinners] = useState([]);
    const [validatePayoutAmount, setValidatePayoutAmount] = useState("");
    const [userSubscriptions, setUserSubscriptions] = useState([]);
   

    useEffect(() => {
        dispatch(clearMessage());
        if (PoolID) {

            Promise.all([
                PoolService.getPoolsById(PoolID),
                PoolService.getWinnersByPools(PoolID),
                PoolService.getWinnersByPools(PoolID),
                PoolService.getFinalWinnerByPools(PoolID),
            ]).then((response) => {
                dispatch(setProgress(100));

                if (response[0].data.response_body.Pool) {
                    setPool(response[0].data.response_body.Pool)

                    setWinnerMultiSelect(response[0].data.response_body.Pool.PoolsMembers.filter(x => x.memberID != x.ownerID).map(function (element) {
                        return { name: (element.User.firstName + ' ' + element.User.lastName), id: element.User.id }
                    }));
                }

                if (response[1].data.response_body.winners) {
                    setWinners(response[1].data.response_body.winners.map((winner) => { winner.isYes = false; return winner; }));
                }

                if (response[2].data.response_body.winners) {
                    setWinnerFinalMultiSelect(response[2].data.response_body.winners.map(function (element) {
                        return { name: (element.User.firstName + ' ' + element.User.lastName), id: element.User.id }
                    }));
                }

                if (response[3].data.response_body.Winners) {
                    setFinalWinners(response[3].data.response_body.Winners);
                }

            }).catch((error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            });

            if (currentUser) {

                Promise.all([
                    PoolService.haveVotedForPool(PoolID, currentUser.user.id),
                    UserService.getSubscription(currentUser.user.id)
                ]).then((response) => {
                    dispatch(setProgress(100));

                    if (response[0].data.response_body.Votes) {
                        setVotes(response[0].data.response_body.Votes);
                    }

                    if (response[1].data.response_body.Subscription) {
                        setUserSubscriptions(response[1].data.response_body.Subscription);
                    }

                }).catch((error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    dispatch(setMessage(_content));
                    dispatch(setProgress(100));
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }
                });
            }
        }

    }, [dispatch]);


    const onWinnerSelect = (selectedList, selectedItem) => {
        selectedItem.amount = 0;
        const winners = [...selectedWinners, selectedItem];
        setSelectedWinners(winners);
    }

    const onWinnerRemove = (selectedList, removedItem) => {
        const updatedWinner = selectedWinners.filter((win) => { return win.id != removedItem.id });
        setSelectedWinners(updatedWinner);
    }

    const onFinalWinnerSelect = (selectedList, selectedItem) => {
        const winners = [...selectedFinalWinners, selectedItem];
        setSelectedFinalWinners(winners);
    }

    const onFinalWinnerRemove = (selectedList, removedItem) => {
        const updatedWinner = selectedFinalWinners.filter((win) => { return win.id != removedItem.id });
        setSelectedFinalWinners(updatedWinner);
    }

    const onAddVote = (winnerId, isYes) => {

        dispatch(setProgress(progress + 50));
        PoolService.addVoteForWinner(PoolID, winnerId, isYes, currentUser.user.id).then(
            (response) => {

                setWinners(response.data.response_body.Votes)
                dispatch(setProgress(100));

                dispatch(setProgress(progress + 50));
                PoolService.haveVotedForPool(PoolID, currentUser.user.id).then(
                    (response1) => {
                        if (response1.data.response_body.Votes) {
                            setVotes(response1.data.response_body.Votes);
                        }
                        dispatch(setProgress(100));
                    },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.response_message) ||
                            error.message ||
                            error.toString();

                        dispatch(setMessage(_content));
                        dispatch(setProgress(100));

                        if (error.response && error.response.status === 401) {
                            EventBus.dispatch("logout");
                        }
                        if (error.response && error.response.status === 500) {
                            EventBus.dispatch("logout");
                        }

                    }
                );
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );
    }

    const onSaveFinalWinner = () => {
        if (selectedFinalWinners.length > 0) {
            selectedFinalWinners.forEach(element => {

                dispatch(setProgress(progress + 50));
                PoolService.updateFinalWinner(PoolID, element.id).then(
                    (response) => {

                        setFinalWinners(response.data.response_body.Winners);
                        dispatch(setProgress(100));
                    },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.response_message) ||
                            error.message ||
                            error.toString();

                        dispatch(setMessage(_content));
                        dispatch(setProgress(100));

                        if (error.response && error.response.status === 401) {
                            EventBus.dispatch("logout");
                        }
                        if (error.response && error.response.status === 500) {
                            EventBus.dispatch("logout");
                        }

                    }
                );
            });
        }
    }

    const handleSaveWinnders = () => {

        let totalContributed = 0;

        pool.PoolsMembers.forEach((poolMember, index) => {
            totalContributed += parseFloat(poolMember.contributed);
        });

        let fieldsFailedValidation = 0;

        selectedWinners.forEach((winner, index) => {

            if (parseFloat(winner.amount) == 0) {
                setValidatePayoutAmount("Payout amount for any member cannot be zero");
                fieldsFailedValidation = fieldsFailedValidation + 1;
                return;
            }

            if (parseFloat(winner.amount) > parseFloat(totalContributed)) {
                setValidatePayoutAmount("Payout amount for any member cannot be greater than total amount contributed for the pool.");
                fieldsFailedValidation = fieldsFailedValidation + 1;
                return;
            }

        });

        if (fieldsFailedValidation > 0) {
            return;
        }

        setValidatePayoutAmount("");
        selectedWinners.forEach((winner, index) => {


            dispatch(setProgress(progress + 50));
            PoolService.addWinnerForPool(pool.id, winner.id, currentUser.user.id, calculateAmountWithDeductedServiceFee(parseFloat(winner.amount))).then(
                (response) => {

                    setWinners(response.data.response_body.Winners.map((winner) => { winner.isYes = false; return winner; }));
                    dispatch(setProgress(100));
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    dispatch(setMessage(_content));
                    dispatch(setProgress(100));

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );

        });


    }

    const calculateServiceFee = (amount) => {
        let actualServiceFee = 0;
        {/*
        if (parseFloat(amount) <= 99) {
            actualServiceFee = POOl_SERVICE_CHARGE_PERCENT_UPTO_99;
        }

        if (parseFloat(amount) >= 100 && parseFloat(amount) <= 499) {
            actualServiceFee = POOl_SERVICE_CHARGE_PERCENT_BETWEEN_100_AND_499;
        }

        if (parseFloat(amount) >= 500) {
            actualServiceFee = POOl_SERVICE_CHARGE_PERCENT_MORE_THAN_499;
        }*/}

        return actualServiceFee;
    }

    const calculateAmountWithDeductedServiceFee = (amount) => {
        let sfee = 0.00;
        if (userSubscriptions.PoolsPlan.type == 'FREE') {
            sfee = calculateServiceFee(parseFloat(amount));
        }
        let serviceFee = (parseFloat(sfee) / 100) * parseFloat(amount);
        return ((parseFloat(amount) - serviceFee));
    }

    const handleVoteYesOrNO = (nextItem, e) => {
        let nextWinners = [...winners];
        let winner = winners.find(x => x.id == nextItem.id);
        winner.isYes = e;
        let index = winners.findIndex(x => x.id == nextItem.id);
        nextWinners[index] = winner;
        setWinners(nextWinners);
    }

    return (
        <>

            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Payout" }} />

                    <div className="pool-details-area">
                        <div className="pool-topbar d-flex justify-content-between">
                            <div className="back-button-area d-flex align-items-center">
                                <button onClick={() => navigate(-1)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                                <h2 className="text-title-1 m-0">Pool Details</h2>
                            </div>

                        </div>
                        {
                            ((Object.keys(pool).length > 0)) && (
                                <div className="card">
                                    <div className="card-header">
                                        Pool details
                                    </div>
                                    <div className="card-body">
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Visibility</p>
                                            <p><span className="badge-negative">{pool.is_private == 1 ? "Public" : "Private"}</span></p>
                                        </div>
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Default buy in amount</p>
                                            <p className="text-body-2">${pool.defaultBuy_in_amount}</p>
                                        </div>
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Goal amount</p>
                                            <p className="text-body-2">${pool.goal_amount}</p>
                                        </div>
                                        <div className='pool-data-item'>
                                            <p className='text-title-3'>Total Amount contributed</p>
                                            <p className='text-body-2'>{pool.PoolsMembers.length > 0 ? "$" + pool.PoolsMembers.reduce((accumulator, current) => { return accumulator + parseFloat(current.contributed); }, 0) : "$0"}</p>
                                        </div>
                                        {currentUser && (
                                            <div className='pool-data-item'>
                                                <p className='text-title-3'>Amount contributed (Me)</p>
                                                <p className='text-body-2'>{pool.PoolsMembers.filter(member => member.memberID === currentUser.user.id).length > 0 ? "$" + pool.PoolsMembers.filter(member => member.memberID === currentUser.user.id)[0].contributed : "$0"}</p>
                                            </div>
                                        )}
                                        <div className="pool-data-item">
                                            <p className="text-title-3">Created Date</p>
                                            <p className="text-body-2">{Moment(convertUTCDateToLocalDate(new Date(pool.createdAt))).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                        </div>
                                    </div>

                                </div>
                            )}
                        <br></br>
                        {
                            ((Object.keys(pool).length > 0))
                            && (pool.status > 0)
                            && (currentUser)
                            && (pool.PoolsPermissions.filter(x => x.memberID == currentUser.user.id && (x.roleID == 1 || x.roleID == 2)).length > 0)
                            && (finalWinners.length == 0) && (
                                <div className="card">
                                    <div className="card-header">
                                        Add  Payouts
                                    </div>
                                    <div className="card-body">

                                        <Multiselect
                                            options={winnerMultiSelect} // Options to display in the dropdown
                                            onSelect={onWinnerSelect}
                                            onRemove={onWinnerRemove} // Preselected value to persist in dropdown                                                                          
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {
                                                (selectedWinners) && (selectedWinners.length > 0) && selectedWinners.map(function (nextItem, j) {
                                                    return (
                                                        <div key={nextItem.id} style={{ marginLeft: '50px' }}>
                                                            <div className='row'>
                                                                <label>{nextItem.name}</label>
                                                            </div>
                                                            <div className='row'>
                                                                <input type='number' min="0.00" step="0.05" className='form-control' style={{ width: '100px' }} onChange={(e) => nextItem.amount = e.target.value} defaultValue={nextItem.amount} />
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                        </div>
                                        <br></br>
                                        {
                                            (validatePayoutAmount) && (
                                                <div className="alert alert-danger" role="alert">
                                                    {validatePayoutAmount}
                                                </div>
                                            )
                                        }

                                        <br></br>
                                        <button className="btn btn-primary col-md-2" style={{ float: 'right' }} onClick={handleSaveWinnders} > Submit</button>

                                    </div>
                                </div>
                            )}
                        <br></br>
                        {(winners.length > 0) && (
                            <div className="card">
                                <div className="card-header">
                                    Payouts
                                </div>
                                <div className="card-body">
                                    <div className="alert alert-info" role="alert">
                                        Vote on who to be choosen for payouts
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {(winners) && (winners.length > 0) && winners.map(function (nextItem, j) {
                                            return (
                                                <div key={nextItem.id} style={{ marginLeft: '50px', width: '250px' }} className="card">
                                                    <h5 className="card-header">Vote</h5>
                                                    <div className="card-body">
                                                        <div className='row'>
                                                            <label>{nextItem.User.firstName + ' ' + nextItem.User.lastName}</label>
                                                        </div>
                                                        <div className='row center'>
                                                            <label>Votes (Yes): {nextItem.isYesCount ? nextItem.isYesCount : 0}</label>
                                                            <label>Votes (No): {nextItem.isNoCount ? nextItem.isNoCount : 0}</label>
                                                        </div>
                                                        <div className='row center'>
                                                            <label>Amount: ${nextItem.amount}</label>
                                                        </div>
                                                        <div className='row center'>

                                                            {(votes) && (votes.length == 0) && (
                                                                <div >
                                                                    <h3 style={{ textAlign: 'center' }}>Vote (no/yes)</h3>

                                                                    <div className='row'>
                                                                        <div className='col'><h3>No</h3></div>
                                                                        <div className='col'><Switch onChange={(e) => handleVoteYesOrNO(nextItem, e)} checked={nextItem.isYes} /></div>
                                                                        <div className='col'><h3>Yes</h3></div>
                                                                    </div>

                                                                    <br></br>

                                                                    <button className="btn btn-primary" style={{ float: 'right' }} onClick={() => onAddVote(nextItem.winnerId, nextItem.isYes)} > Add</button>

                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                        <br></br>
                        <div className="card">
                            <div className="card-header">
                                Final  Payouts
                            </div>
                            <div className="card-body">

                                <div className="alert alert-info" role="alert">
                                    Final payout will show here once voting deadline has passed.
                                </div>
                                {
                                    ((Object.keys(pool).length > 0))
                                    && (pool.status > 0)
                                    && (currentUser)
                                    && (pool.PoolsPermissions.filter(x => x.memberID == currentUser.user.id && (x.roleID == 1 || x.roleID == 2)).length > 0)
                                    && (finalWinners.length == 0) && (
                                        <div>
                                            <Multiselect
                                                options={winnerFinalMultiSelect} // Options to display in the dropdown
                                                onSelect={onFinalWinnerSelect}
                                                onRemove={onFinalWinnerRemove}
                                                displayValue="name" // Property name to display in the dropdown options
                                            />
                                            <br></br>

                                            <button className="btn btn-primary" onClick={onSaveFinalWinner}>Submit</button>

                                        </div>
                                    )
                                }
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {
                                        (finalWinners) && (finalWinners.length > 0) && finalWinners.map(function (nextItem, j) {
                                            return (
                                                <div key={nextItem.id} className='card' style={{ marginLeft: '50px', width: '200px' }}>
                                                    <div className='card-body'>
                                                        <div className='row'>
                                                            <label>{nextItem.User.firstName} {nextItem.User.lastName}</label>
                                                        </div>
                                                        <div className='row center'>
                                                            <label>Votes (Yes): {nextItem.isYesCount ? nextItem.isYesCount : 0}</label>
                                                            <label>Votes (No): {nextItem.isNoCount ? nextItem.isNoCount : 0}</label>
                                                        </div>
                                                        <div className='row'>
                                                            <label>${nextItem.amount}</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>

                            </div>
                        </div>

                    </div>
                </div >
            </main >
        </>
    )
}

export default Payout;