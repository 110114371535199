import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import PoolService from "../services/pool.service";



export const getPoolEventMessage = createAsyncThunk(
    "pool/getPoolEventMessage",
    async ({ id }, thunkAPI) => {
        try {
            const response = await PoolService.getPoolEventMessage(id);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getBettingPoolMessage = createAsyncThunk(
    "pool/getBettingPoolMessage",
    async ({ id }, thunkAPI) => {
        try {
            const response = await PoolService.getBettingPoolMessage(id);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const createPoolsMessage = createAsyncThunk(
    "pool/createPoolsMessage",
    async ({ converationId, message, userId }, thunkAPI) => {
        try {
            const response = await PoolService.createPoolsMessage(converationId, message, userId);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const createPoolsEventMessage = createAsyncThunk(
    "pool/createPoolsEventMessage",
    async ({ converationId, message, userId }, thunkAPI) => {
        try {
            const response = await PoolService.createPoolsEventMessage(converationId, message, userId);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);



const initialState = {
    rooms: [],
    headerNotificationUnRead: 0,
    roomSelected: {}
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setRooms: (state, action) => {
            state.rooms = action.payload;
        },
        addMessageToRoom: (state, action) => {

            const { roomId, message } = action.payload;

            if (state.roomSelected.roomId === roomId) {

                state.roomSelected.messages = [
                    ...state.roomSelected.messages,
                    message
                ]

                state.roomSelected.unread = (parseInt(state.roomSelected.unread) + 1);

            }

            state.rooms = state.rooms.map((room) => {

                if (room.roomId === roomId) {

                    state.headerNotificationUnRead = state.headerNotificationUnRead + parseInt(room.unread) + 1;

                    return {
                        ...room,
                        messages: [
                            ...room.messages,
                            message
                        ],
                        unread: parseInt(room.unread) + 1
                    };

                } else {
                    return room
                }

            });
        },
        addToRoom: (state, action) => {
            const {
                id,
                name,
                conversationType
            } = action.payload;

            state.rooms = [{
                id: id,
                name: name,
                conversationType: conversationType,
                roomId: `${id}_${conversationType}`,
                unread: 0,
                messages: [],
            }, ...state.rooms]
        },
        setRoomSelected: (state, action) => {
            const roomId = action.payload;
            state.roomSelected = state.rooms.find(x => x.roomId === roomId);
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getPoolEventMessage.fulfilled, (state, action) => {

            const { Messages, EventId } = action.payload;

            state.rooms = state.rooms.map((room) => {
                if (room.roomId === `${EventId}_EVENT`) {
                    return {
                        ...room,
                        messages: Messages
                    }

                } else {
                    return room;
                }
            })

        }).addCase(getPoolEventMessage.rejected, (state, action) => {

        }).addCase(getBettingPoolMessage.fulfilled, (state, action) => {
            
            const { Messages, PoolId } = action.payload;

            state.rooms = state.rooms.map((room) => {
                if (room.roomId === `${PoolId}_POOL`) {
                    return {
                        ...room,
                        messages: Messages
                    }

                } else {
                    return room;
                }
            })

        }).addCase(getBettingPoolMessage.rejected, (state, action) => {

        }).addCase(createPoolsMessage.fulfilled, (state, action) => {

        }).addCase(createPoolsMessage.rejected, (state, action) => {

        }).addCase(createPoolsEventMessage.fulfilled, (state, action) => {

        }).addCase(createPoolsEventMessage.rejected, (state, action) => {

        })

    }
});


const { reducer, actions } = chatSlice;
export const
    {
        setRooms,
        addMessageToRoom,
        addToRoom,
        setRoomSelected
    } = actions;
export default reducer;