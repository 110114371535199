import classNamees from './navigation.module.css';
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate, Link, useSearchParams, useLocation, json } from "react-router-dom";


const NavigationBar = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [userNotifications, setUserNotifications] = useState([]);
    const navigate = useNavigate();

    const handleProfileClick = () => {
        navigate("/account");
    }

    return (

        <div className="dashboard-top-bar">
            <div className="page-title">
                <h1 className="text-headline-1">{props.props.pageName}</h1>
            </div>
        </div>
    );
}

export default NavigationBar;