import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";


import UserService from "../services/user.service";


const user = JSON.parse(localStorage.getItem("user"));


export const getAccountByUserId = createAsyncThunk(
    "pool/getAccountByUserId",
    async ({ }, thunkAPI) => {
        try {
            const response = await UserService.getAccountByUserId();
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


export const getSubscription = createAsyncThunk(
    "pool/getSubscription",
    async ({ userId }, thunkAPI) => {
        try {
            const response = await UserService.getSubscription(userId);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


export const getSubscriptionHistory = createAsyncThunk(
    "pool/getSubscriptionHistory",
    async ({ userId }, thunkAPI) => {
        try {
            const response = await UserService.getSubscriptionHistory(userId);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


export const getSubscriptionPayments = createAsyncThunk(
    "pool/getSubscriptionPayments",
    async ({ userId }, thunkAPI) => {
        try {
            const response = await UserService.getSubscriptionPayments(userId);
            return (response && response.data && response.data.response_body);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.response_message) || error.message || error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);


const initialState = {
    user: {},
    userSubscriptions: [],
    userSubscriptionHistory: [],
    userSubscriptionPayment: []
};


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAccountDetails(state, action) {
            state.user = action.payload;
        },
        setUserSubscriptions(state, action) {
            state.userSubscriptions = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccountByUserId.fulfilled, (state, action) => {
                state.user = action.payload.Account;
            })
            .addCase(getAccountByUserId.rejected, (state, action) => {
                state.user = [];
            })
            .addCase(getSubscription.fulfilled, (state, action) => {
                state.userSubscriptions = action.payload.Subscription;
            })
            .addCase(getSubscription.rejected, (state, action) => {
                state.userSubscriptions = [];
            })
            .addCase(getSubscriptionHistory.fulfilled, (state, action) => {
                state.userSubscriptionHistory = action.payload.History;
            })
            .addCase(getSubscriptionHistory.rejected, (state, action) => {
                state.userSubscriptionHistory = [];
            })
            .addCase(getSubscriptionPayments.fulfilled, (state, action) => {
                state.userSubscriptionPayment = action.payload.Payments;
            })
            .addCase(getSubscriptionPayments.rejected, (state, action) => {
                state.userSubscriptionPayment = [];
            })
    }
});


const { reducer } = userSlice;
export const { setAccountDetails, setUserSubscriptions } = userSlice.actions
export default reducer;