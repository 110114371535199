import axios from "axios";
import "../../config";

const API_URL = global.config.API_URL + 'finance/';




const updateReceivePaymentDetails = (UserID, payout_email_address, payout_payer_id) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.post(API_URL + "update-receive-payment-details", {
        UserID,
        payout_email_address,
        payout_payer_id
    }, { headers });
}

const getAllTransactionsByUserId = (userId) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "transactions-by-userId/" + userId, { headers });
}

const getTotalPoolPaymentByMonths = (createdBy) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "total-pool-payment-by-months/" + createdBy, { headers });
}

const getTotalPoolPaymentByWeek = (createdBy) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "total-pool-payment-by-week/" + createdBy, { headers });
}

const createPaypalOrder = (contributionAmount, discountedContributionAmount, Id, discount, type) => {
    return axios.post(API_URL + "create-paypal-order", {
        contributionAmount, discountedContributionAmount, Id, discount, type
    });
}

const capturePaypalOrder = (orderId, Id, userId, type) => {
    return axios.post(API_URL + "capture-paypal-order", {
        orderId,
        Id,
        userId,
        type
    });
}

const getFinalContributionAmount = (userId, amount) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "final-contribution-amount/" + userId + "/" + amount, { headers });
}

const ManagePaymentService = {
    updateReceivePaymentDetails,
    getAllTransactionsByUserId,
    getTotalPoolPaymentByMonths,
    getTotalPoolPaymentByWeek,
    createPaypalOrder,
    capturePaypalOrder,
    getFinalContributionAmount
}



export default ManagePaymentService;