import classes from './eventDetails.module.css';
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Moment from 'moment';

import { Navigate, useNavigate, Link, useSearchParams, useLocation, json, createSearchParams } from "react-router-dom";
import { loadScript } from "@paypal/paypal-js";
import { renderToString } from 'react-dom/server';
import * as Yup from "yup";
import QRCode from "react-qr-code";
import Multiselect from 'multiselect-react-dropdown';
import { CircularProgressbarWithChildren, CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Switch from "react-switch";
import { Chart } from "react-google-charts";
import { ReactTags } from 'react-tag-autocomplete';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-circular-progressbar/dist/styles.css';
import { setRoomSelected } from "../../slices/chat";
import { setEventSelected } from "../../slices/pool";
import NavigationBar from '../../components/navigation/navigation';
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import { convertUTCDateToLocalDate } from '../../helpers/dateTimeHelper';
import useMediaQuery from "../../hooks/useMediaQuery";
import { clearMessage, setMessage } from "../../slices/message";
import "../../../config";
import UserService from "../../services/user.service";
import { setProgress } from "../../slices/loader";
import ManagePaymentService from "../../services/managePayment.service";
import Chat from "../../components/chat/chat";
import "./eventDetails.css";


const EventDetails = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const PAYPAL_CLIENT_ID = global.config.PAYPAL_CLIENT_ID;
    const PAYPAL_MERCHANT_ID = global.config.PAYPAL_MERCHANT_ID;
    const PAYPAL_COMPONENT = global.config.PAYPAL_COMPONENT;

    const { user: currentUser } = useSelector((state) => state.auth);
    const { rooms, roomSelected, roomMessages } = useSelector((state) => state.chat);
    const [loading, setLoading] = useState(false);

    const [paypal, setPaypal] = useState({});
    const [applepay, setApplepay] = useState({});
    const [applepayConfig, setApplePayConfig] = useState({});

    const searchParams = useLocation();
    const [event, setEvent] = useState({});
    const EventID = new URLSearchParams(searchParams.search).get("id");
    const { progress } = useSelector((state) => state.loader);
    const [pools, setPools] = useState([]);

    const [isBusyInviteContacts, setIsBusyInviteContacts] = useState(false)
    const [selectedContacts, setSelectedContacts] = useState([])
    const [inviteContacts, setInviteContacts] = useState([]);
    const [inviteStatus, setInviteStatus] = useState("");
    const [tipAmount, setTipAmount] = useState(5);
    const [amountValidation, setAmountValidation] = useState("");
    const [paymentMessage, setPaymentMessage] = useState({});


    const refInvitePopup = useRef(null);
    const action = new URLSearchParams(searchParams.search).get("action");
    const [monthChart, setMonthChart] = useState([]);
    const [monthChartTicks, setMonthChartTicks] = useState([]);
    const [weekChart, setWeekChart] = useState([]);
    const [weekChartTicks, setWeekChartTicks] = useState([]);
    const [monthTipsChart, setMonthTipsChart] = useState([]);
    const [monthTipsChartTicks, setMonthTipsChartTicks] = useState([]);
    const [weekTipsChart, setWeekTipsChart] = useState([]);
    const [weekTipsChartTicks, setWeekTipsChartTicks] = useState([]);


    {/* PAYPAL INTIALIZE START */ }

    useEffect(() => {
        (async function () {
            try {
                let _paypal = await loadScript(
                    {
                        ["client-id"]: PAYPAL_CLIENT_ID,
                        currency: "USD",
                        ["merchant-id"]: [PAYPAL_MERCHANT_ID],
                        components: PAYPAL_COMPONENT
                    });

                setPaypal(_paypal);

                if (PAYPAL_COMPONENT.filter(x => x === "applepay").length > 0) {
                    let _applePay = _paypal.Applepay();
                    setApplepay(_applePay);

                    let _appleConfig = await _applePay.config();
                    setApplePayConfig(_appleConfig);
                }

            } catch (error) {
                console.log("failed to load the PayPal JS SDK script", error);
            }
        })();
    }, []);

    {/* PAYPAL INTIALIZE END */ }

    {/* APPLE PAY START */ }

    const createApplePayOrder = useCallback(async () => {
        let result = {};
        try {

            await ManagePaymentService.createPaypalOrder(
                tipAmount,
                tipAmount,
                EventID,
                0,
                'EVENT'
            ).then(
                (response) => {
                    if (response) {
                        result = response.data.response_body.Order;
                    }
                },
                (error) => {
                    throw error;
                }
            );
        }
        catch (err) {
            throw err
        }
        return result.id;
    }, [tipAmount]);

    const onApplePayApprove = useCallback(async (id) => {

        await ManagePaymentService.capturePaypalOrder(
            id,
            EventID,
            currentUser ? currentUser.user.id : null,
            'EVENT').then(
                async (response) => {
                    if (response) {
                        setEvent(response.data.response_body.Capture)
                        response.data.response_body.Capture.photo = response.data.response_body.Capture.photo ?
                            global.config.BASE_API_URL + '/public/' + response.data.response_body.Capture.photo :
                            response.data.response_body.Capture.photo;
                        setPaymentMessage({
                            successStatus: true,
                            message: "Payment Successful"
                        })

                    }
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setPaymentMessage({
                        successStatus: false,
                        message: _content
                    })
                }
            );
    }, []);

    const ApplePayButton = () => {

        const containerDiv = useRef();

        useEffect(() => {

            if (containerDiv.current) {
                if (!window.ApplePaySession) {
                    console.log('This device does not support Apple Pay');
                    return;
                }
                if (!window.ApplePaySession.canMakePayments()) {
                    console.log('This device is not capable of making Apple Pay payments');
                    return;
                }

                containerDiv.current.innerHTML =
                    '<apple-pay-button id="btn-appl" buttonstyle="black" type="buy" locale="en">';

                document.getElementById("btn-appl").addEventListener("click", handeApplePayment);
            }

        }, [containerDiv.current]);

        return (<>
            <div ref={containerDiv}></div>
        </>);
    }

    const handeApplePayment = () => {

        if (!applepayConfig.isEligible) {
            console.log("applepay is not eligible");
            return;
        }

        const paymentRequest = {
            countryCode: applepayConfig.countryCode,
            merchantCapabilities: applepayConfig.merchantCapabilities,
            supportedNetworks: applepayConfig.supportedNetworks,
            currencyCode: applepayConfig.currencyCode,
            requiredShippingContactFields: ["name", "phone", "email", "postalAddress"],
            requiredBillingContactFields: ["postalAddress"],
            total: {
                label: `${event.name}`,
                type: "final",
                amount: tipAmount.toString(),
            }

        };

        let session = new window.ApplePaySession(4, paymentRequest);

        // Merchant Validation
        session.onvalidatemerchant = async function (event) {
            applepay.validateMerchant({
                validationUrl: event.validationURL,
                displayName: `${event.name}`
            })
                .then(validateResult => {
                    session.completeMerchantValidation(validateResult.merchantSession);
                })
                .catch(validateError => {
                    console.error(validateError);
                    session.abort();
                });
        }

        session.onpaymentmethodselected = function (event) {
            session.completePaymentMethodSelection({
                newTotal: paymentRequest.total,
            });
        }

        session.onpaymentauthorized = async function (event) {
            try {

                let orderId = await createApplePayOrder();

                await applepay.confirmOrder(
                    {
                        orderId: orderId,
                        token: event.payment.token,
                        billingContact: event.payment.billingContact,
                        shippingContact: event.payment.shippingContact
                    });

                await onApplePayApprove(orderId);

                session.completePayment({
                    status: window.ApplePaySession.STATUS_SUCCESS,
                });

            } catch (err) {
                console.error(err);
                session.completePayment({
                    status: window.ApplePaySession.STATUS_FAILURE,
                });
            }
        }

        session.oncancel = function (event) {
            console.log("Apple Pay Cancelled !!")
        }

        session.begin();

    }

    {/* APPLE PAY END */ }


    {/* PAYPAL START */ }

    const createPayPalOrder = useCallback(async () => {
        let result = {};
        try {

            await ManagePaymentService.createPaypalOrder(
                tipAmount,
                tipAmount,
                EventID,
                0,
                'EVENT'
            ).then(
                (response) => {
                    if (response) {
                        result = response.data.response_body.Order;
                    }
                },
                (error) => {
                    throw error;
                }
            );
        }
        catch (err) {
            throw err
        }
        return result.id;
    }, [tipAmount]);

    const onPayPalApprove = useCallback(async (order) => {

        await ManagePaymentService.capturePaypalOrder(
            order.orderID,
            EventID,
            currentUser ? currentUser.user.id : null,
            'EVENT').then(
                async (response) => {
                    if (response) {
                        setEvent(response.data.response_body.Capture)
                        response.data.response_body.Capture.photo = response.data.response_body.Capture.photo ?
                            global.config.BASE_API_URL + '/public/' + response.data.response_body.Capture.photo :
                            response.data.response_body.Capture.photo;
                        setPaymentMessage({
                            successStatus: true,
                            message: "Payment Successful"
                        })

                    }
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    setPaymentMessage({
                        successStatus: false,
                        message: _content
                    })
                }
            );
    }, []);

    const PayPalButtons = () => {

        const containerDiv = useRef();

        useEffect(() => {
            if (paypal) {
                if (containerDiv.current) {
                    (async function () {

                        await paypal.Buttons({
                            createOrder: createPayPalOrder,
                            onApprove: onPayPalApprove
                        }).render(containerDiv.current);

                    })();
                }
            }

        }, [paypal, containerDiv.current]);

        return (<>
            <div ref={containerDiv}></div>
        </>);
    };

    {/* PAYPAL END */ }

    useEffect(() => {

        dispatch(setProgress(progress + 50));

        Promise.all(currentUser ? [
            PoolService.getPoolsEventById(EventID),
            PoolService.getPoolsByEventId(EventID),
            PoolService.getTotalPoolPaymentInEventByMonths(EventID),
            PoolService.getTotalPoolInEventPaymentByWeek(EventID),
            PoolService.getTotalTipsInEventByMonths(EventID),
            PoolService.getTotalTipsInEventPaymentByWeek(EventID)
        ] : [
            PoolService.getPoolsEventById(EventID),
            PoolService.getPoolsByEventId(EventID),
            PoolService.getTotalPoolPaymentInEventByMonths(EventID),
            PoolService.getTotalPoolInEventPaymentByWeek(EventID),
            PoolService.getTotalTipsInEventByMonths(EventID),
            PoolService.getTotalTipsInEventPaymentByWeek(EventID)
        ]).then(async (response) => {
            dispatch(setProgress(100));

            if (response[0].data.response_body.BettingPoolEvent) {
                setEvent(response[0].data.response_body.BettingPoolEvent);
                dispatch(setEventSelected(response[0].data.response_body.BettingPoolEvent))
            }

            if (response[1].data.response_body.Pools) {
                setPools(response[1].data.response_body.Pools)
            }

            if (response[2].data.response_body.Contribution) {
                let chartData = response[2].data.response_body.Contribution;
                setMonthChart([["Month", "Contributed"], ...chartData]);
                let maxTicksValue = Math.max(...chartData.map(x => x[1]));
                let ticks = [];
                for (var i = 0; i <= maxTicksValue; i = i + 5) {
                    ticks.push(i);
                }
                if (maxTicksValue == 0) {
                    ticks = [0, 10, 20, 30];
                }
                setMonthChartTicks(ticks);
            }

            if (response[3].data.response_body.Contribution) {
                let chartData = response[3].data.response_body.Contribution;
                setWeekChart([["Week", "Previous", "Current"], ...chartData]);
                let maxTicksValue = Math.max(...chartData.map(x => x[1]));
                let ticks = [];
                for (var i = 0; i <= maxTicksValue; i = i + 5) {
                    ticks.push(i);
                }
                if (maxTicksValue == 0) {
                    ticks = [0, 10, 20, 30];
                }
                setWeekChartTicks(ticks);
            }

            if (response[4].data.response_body.Contribution) {
                let chartData = response[4].data.response_body.Contribution;
                setMonthTipsChart([["Month", "Contributed"], ...chartData]);
                let maxTicksValue = Math.max(...chartData.map(x => x[1]));
                let ticks = [];
                for (var i = 0; i <= maxTicksValue; i = i + 5) {
                    ticks.push(i);
                }
                if (maxTicksValue == 0) {
                    ticks = [0, 10, 20, 30];
                }
                setMonthTipsChartTicks(ticks);
            }

            if (response[5].data.response_body.Contribution) {
                let chartData = response[5].data.response_body.Contribution;
                setWeekTipsChart([["Week", "Previous", "Current"], ...chartData]);
                let maxTicksValue = Math.max(...chartData.map(x => x[1]));
                let ticks = [];
                for (var i = 0; i <= maxTicksValue; i = i + 5) {
                    ticks.push(i);
                }
                if (maxTicksValue == 0) {
                    ticks = [0, 10, 20, 30];
                }
                setWeekTipsChartTicks(ticks);
            }

        }).catch((error) => {
            const _content =
                (error.response &&
                    error.response.data &&
                    error.response.data.response_message) ||
                error.message ||
                error.toString();

            dispatch(setMessage(_content));
            dispatch(setProgress(100));
            if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
            }
            if (error.response && error.response.status === 500) {
                EventBus.dispatch("logout");
            }
        });

    }, [dispatch]);

    useEffect(() => {

        if (action == 'invited' && refInvitePopup.current) {
            refInvitePopup.current.click();
        }

    }, [refInvitePopup.current]);


    function debounce(fn, delay = 100) {
        let timeoutID

        return function (...args) {
            clearTimeout(timeoutID)
            timeoutID = setTimeout(() => fn(...args), delay)
        }
    }

    const handleJoin = (PoolID) => {

        PoolService.addMemberToPool(currentUser.user.id, PoolID).then(
            (response) => {

                let Pools = response.data.response_body.Pools;

                navigate({
                    pathname: '/pool-details',
                    search: '?id=' + PoolID,
                });

            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );
    }

    const handleSendInvite = (id) => {

        dispatch(setProgress(progress + 50));
        let recepients = selectedContacts.map((x) => { return x.value });
        PoolService.inviteEventNotification(recepients, id).then(
            (response) => {
                setInviteStatus('Invite sent !')
                dispatch(setProgress(100));
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );

        setSelectedContacts([]);
        setInviteContacts([]);
    }

    const validateTipAmount = (amount) => {
        if (amount === "") {
            setAmountValidation("Tip  amount cannot be empty.");
        }
        else if (parseFloat(amount) <= 0) {
            setAmountValidation("Tip amount must be greater than zero.");
        }
        else {
            setAmountValidation("");
            setTipAmount(parseFloat(amount));
        }
    }

    const onAddInviteContacts = useCallback(
        (newTag) => {
            setSelectedContacts([...selectedContacts, newTag])
            setInviteContacts([])
        },
        [selectedContacts]
    )

    const onDeleteInviteContacts = useCallback(
        (index) => {
            setSelectedContacts(selectedContacts.filter((_, i) => i !== index))
        },
        [selectedContacts]
    )

    const onInputInviteContacts = useCallback(debounce(async (query) => {
        if (isBusyInviteContacts) return

        setIsBusyInviteContacts(true)

        let response = await UserService.searchUsers(currentUser.user.id, query).then(
        ).finally(() => {
            setIsBusyInviteContacts(false)
        });

        let suggestions = response.data.response_body.Users;
        setInviteContacts(suggestions)

    }), [isBusyInviteContacts])




    return (

        < >

            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Event Details" }} />

                    {
                        (Object.keys(event).length > 0)
                        &&
                        (
                            <div className="pool-details-area">
                                <div className="pool-topbar d-flex justify-content-between">
                                    <div className="back-button-area d-flex align-items-center">
                                        <button onClick={() => navigate(-1)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                        <h2 className="text-title-1 m-0">Event Details</h2>
                                    </div>
                                    <div className="pool-buttons-area">
                                        {(currentUser) && (
                                            <a href="#" className="edit-pool" onClick={() => navigate("/event-edit?id=" + EventID)} >
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.5 1H2.66667C2.22464 1 1.80072 1.17559 1.48816 1.48816C1.17559 1.80072 1 2.22464 1 2.66667V14.3333C1 14.7754 1.17559 15.1993 1.48816 15.5118C1.80072 15.8244 2.22464 16 2.66667 16H14.3333C14.7754 16 15.1993 15.8244 15.5118 15.5118C15.8244 15.1993 16 14.7754 16 14.3333V8.5" stroke="#67717C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M14.1791 0.483986C14.489 0.174095 14.9093 -4.61774e-09 15.3476 0C15.7858 4.61774e-09 16.2061 0.174095 16.516 0.483986C16.8259 0.793877 17 1.21418 17 1.65243C17 2.09068 16.8259 2.51099 16.516 2.82088L9.11586 10.221L6 11L6.77896 7.88414L14.1791 0.483986Z" fill="#67717C"></path>
                                                </svg>
                                            </a>
                                        )}
                                        <Link className="new-pool-button" to={"/create-pool?type=Event+Pool&formatId=13&typeId=13&id=" + EventID}>
                                            Create New Pool
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 4.16675V15.8334" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <path d="M4.16669 10H15.8334" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                            </svg>
                                        </Link>
                                        {
                                            (currentUser) && (
                                                <button className="invite-member text-title-4" data-bs-toggle="modal" data-bs-target="#invitePopUp">
                                                    Invite Member
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 4.16675V15.8334" stroke="#00582E" strokeWidth="1.5"
                                                            strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M4.16666 10H15.8333" stroke="#00582E" strokeWidth="1.5"
                                                            strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                            )
                                        }
                                        <button style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#joinNotification" ref={refInvitePopup}></button>
                                        <Link className='btn btn-outline-primary' to={"/event-payout?eventId=" + EventID}>
                                            Payout
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 4.16675V15.8334" stroke="#00582E" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M4.16666 10H15.8333" stroke="#00582E" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>

                                {
                                    (paymentMessage) && (paymentMessage.successStatus) &&
                                    ((paymentMessage.successStatus === true) && (<div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <strong>Success!</strong> {paymentMessage.message}
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>)) ||
                                    ((paymentMessage.successStatus === false) && (<div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        <strong>Failed!</strong> {paymentMessage.message}
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>))
                                }

                                <div className="pool-details-content">
                                    <div className="pool-details-thumbnail">
                                        <img src={event.photo ? event.photo : "assets/img/pool-thumbnails/pool-details.png"} alt="pool-thumbnail" />
                                    </div>
                                    <div className="pool-information">
                                        <h2 className="text-headline-1 m-0">{event.name}</h2>
                                        <div className="pool-data-container">
                                            <div className="pool-data-item">
                                                <p className="text-title-3">Visibility</p>
                                                <p><span className="badge-negative">{event.visibility == 1 ? "Public" : "Private"}</span></p>
                                            </div>
                                            <div className="pool-data-item">
                                                <p className="text-title-3">Description</p>
                                                <p className="text-body-2">{event.description}</p>
                                            </div>
                                            <div className="pool-data-item">
                                                <p className="text-title-3">Address</p>
                                                <p className="text-body-2">{event.address}  {event.city}  {event.state}  {event.state}</p>
                                            </div>
                                            <div className="pool-data-item">
                                                <p className="text-title-3">Max Members In Pool Allowed</p>
                                                <p className="text-body-2">{event.maxMembersInPool}</p>
                                            </div>
                                            <div className="pool-data-item">
                                                <p className="text-title-3">Max Pools Allowed</p>
                                                <p className="text-body-2">{event.maxPool}</p>
                                            </div>
                                            <div className="pool-data-item">
                                                <p className="text-title-3">Event Date</p>
                                                <p className="text-body-2">{Moment(convertUTCDateToLocalDate(new Date(event.eventDate))).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                            </div>
                                            <div className="pool-data-item">
                                                <p className="text-title-3">Website</p>
                                                <p className="text-body-2"><a href={event.website} target='_blank'>{event.website}</a></p>
                                            </div>
                                            <div className="pool-data-item">
                                                <p className="text-title-3">Created By</p>
                                                <p className="text-body-2"><Link to={"/profile?id=" + event.User.id}>{event.User.firstName + " " + event.User.lastName}</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pool-fund-area">
                                        <div>
                                            <h3>Support the event and Donate</h3>
                                            <div className='row'>
                                                <table style={{ margin: '10px' }}>
                                                    <tbody>
                                                        <tr key={0}>
                                                            <td colSpan={2}>
                                                                <div className="input-group" style={{ padding: '0px', width: '40%', margin: 'auto' }}>
                                                                    <div className="input-group-prepend" >
                                                                        <span className="input-group-text">$</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" style={{ padding: '0px' }} defaultValue={tipAmount} onChange={event => validateTipAmount(event.target.value)} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pool-fund-area" style={{ margin: 'auto' }}>
                                        {
                                            (amountValidation) && (
                                                <div className="alert alert-danger" role="alert">
                                                    {amountValidation}
                                                </div>
                                            )
                                        }

                                        {(!amountValidation) && (
                                            <>
                                                <div className='row'>
                                                    {
                                                        (paypal) && (<>
                                                            <PayPalButtons />
                                                            <ApplePayButton />
                                                        </>)
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <br></br>
                    <br></br>
                    <div className="dashboard-graph-area">
                        <div className="dashboard-graph">
                            <Chart
                                chartType="AreaChart"
                                width="100%"
                                height="500px"
                                data={monthChart}
                                options={{
                                    title: "Total money in Event pools",
                                    hAxis: { title: "Month", titleTextStyle: { color: "#BDC2C6" }, color: "#BDC2C6" },
                                    vAxis: { ticks: monthChartTicks, minValue: 0, },
                                    chartArea: { width: "80%", height: "80%" },
                                    colors: ['#c5914b'],
                                    legend: "none",
                                    lineWidth: 3,
                                    animation: {
                                        startup: true,
                                        easing: "linear",
                                        duration: 1500,
                                    },
                                    responsive: true,
                                }}
                                chartEvents={[
                                    {
                                        eventName: "ready",
                                        callback: ({ chartWrapper, google }) => {

                                        }
                                    }
                                ]}
                            />
                        </div>

                        <div className="dashboard-graph">
                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height="500px"
                                data={weekChart}
                                options={{
                                    title: "Total money in Event pools",
                                    chartArea: { width: "80%", height: "80%" },
                                    isStacked: true,
                                    legend: "none",
                                    bar: { groupWidth: "10%" },
                                    hAxis: {
                                        title: "Week",
                                        minValue: 0,
                                    },
                                    vAxis: {
                                        title: "Contributed",
                                        ticks: weekChartTicks,
                                        minValue: 0,
                                    },
                                    colors: ['#FFC371', '#FF796E'],
                                    animation: {
                                        startup: true,
                                        easing: "linear",
                                        duration: 1500,
                                    },
                                    responsive: true,
                                }}
                                chartEvents={[
                                    {
                                        eventName: "ready",
                                        callback: ({ chartWrapper, google }) => {

                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <div className="dashboard-graph-area">
                        <div className="dashboard-graph">
                            <Chart
                                chartType="AreaChart"
                                width="100%"
                                height="500px"
                                data={monthTipsChart}
                                options={{
                                    title: "Total money in Event Tips",
                                    hAxis: { title: "Month", titleTextStyle: { color: "#BDC2C6" }, color: "#BDC2C6" },
                                    vAxis: { ticks: monthTipsChartTicks, minValue: 0, },
                                    chartArea: { width: "80%", height: "80%" },
                                    colors: ['#c5914b'],
                                    legend: "none",
                                    lineWidth: 3,
                                    animation: {
                                        startup: true,
                                        easing: "linear",
                                        duration: 1500,
                                    },
                                    responsive: true,
                                }}
                                chartEvents={[
                                    {
                                        eventName: "ready",
                                        callback: ({ chartWrapper, google }) => {

                                        }
                                    }
                                ]}
                            />
                        </div>

                        <div className="dashboard-graph">
                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height="500px"
                                data={weekTipsChart}
                                options={{
                                    title: "Total money in Event Tips",
                                    chartArea: { width: "80%", height: "80%" },
                                    isStacked: true,
                                    legend: "none",
                                    bar: { groupWidth: "10%" },
                                    hAxis: {
                                        title: "Week",
                                        minValue: 0,
                                    },
                                    vAxis: {
                                        title: "Contributed",
                                        ticks: weekTipsChartTicks,
                                        minValue: 0,
                                    },
                                    colors: ['#FFC371', '#FF796E'],
                                    animation: {
                                        startup: true,
                                        easing: "linear",
                                        duration: 1500,
                                    },
                                    responsive: true,
                                }}
                                chartEvents={[
                                    {
                                        eventName: "ready",
                                        callback: ({ chartWrapper, google }) => {

                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <h4>Pools</h4>
                    <br></br>
                    <div className="table-responsive">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Total Amount contributed</th>
                                    <th scope="col">Goal amount</th>
                                    <th scope="col">Private</th>
                                    <th scope="col">Pick Deadline</th>
                                    <th scope="col">Max Members</th>
                                    <th scope="col">Voting Deadline</th>
                                    <th scope="col">Created By</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(pools.length > 0) && pools.map(function (nextItem, j) {
                                    return (
                                        <tr key={nextItem.id}>
                                            <td> <Link to={"/pool-details?id=" + nextItem.id}>{nextItem.name}</Link> </td>
                                            <td>
                                                {nextItem.PoolsMembers.length > 0 ? "$" + nextItem.PoolsMembers.reduce((accumulator, current) => { return accumulator + parseFloat(current.contributed); }, 0) : "$0"}
                                            </td>
                                            <td>{nextItem.goal_amount}</td>
                                            <td>{nextItem.is_private == 1 ? 'Yes' : 'No'}</td>
                                            <td>{Moment(convertUTCDateToLocalDate(new Date(nextItem.PoolsSettings.length > 0 ? nextItem.PoolsSettings[0].pickDeadline : ''))).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                            <td>{nextItem.PoolsSettings.length > 0 ? nextItem.PoolsSettings[0].maxMembers : ''}</td>
                                            <td>{Moment(convertUTCDateToLocalDate(new Date(nextItem.PoolsSettings.length > 0 ? nextItem.PoolsSettings[0].votingDeadline : ''))).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                            <td>
                                                {
                                                    ((currentUser) && (nextItem.PoolsMembers.length >= 0) &&
                                                        (nextItem.PoolsMembers.filter(member => member.memberID === currentUser.user.id).length == 0))
                                                    && (
                                                        <button className='invite-member' style={{ width: '66px', border: 'none' }} onClick={() => handleJoin(nextItem.id)}>Join</button>
                                                    )
                                                }
                                            </td>
                                            <td>
                                                <Link to={"/profile?id=" + nextItem.User.id}>{nextItem.User.firstName + " " + nextItem.User.lastName}</Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {currentUser &&
                        Object.keys(event).length > 0 &&
                        (
                            <Chat
                                id={event.id}
                                type={"EVENT"}
                                sendMessage={props.sendMessage} />
                        )}


                    <div className="modal fade" id="invitePopUp">
                        <div className="modal-dialog" role="document">
                            <div className="modal-body">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-headline-3 m-0">Invite Event Pool Members</h5>
                                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="pool-invitation-container">
                                        {
                                            (inviteStatus.length > 0) && (

                                                <div className="alert alert-success" style={{ width: '80%', marginLeft: '20px' }}>
                                                    {inviteStatus}
                                                </div>
                                            )
                                        }
                                        <div className="pool-invitation-form">
                                            <ReactTags
                                                ariaDescribedBy="async-suggestions-description"
                                                id="async-suggestions-demo"
                                                allowNew
                                                labelText="Select characters"
                                                noOptionsText={isBusyInviteContacts && !inviteContacts.length ? 'Loading...' : 'No characters found'}
                                                onAdd={onAddInviteContacts}
                                                onDelete={onDeleteInviteContacts}
                                                onInput={onInputInviteContacts}
                                                placeholderText="Start typing..."
                                                selected={selectedContacts}
                                                suggestions={inviteContacts}
                                                onValidate={(value) => { return /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/.test(value) }}
                                            />
                                            <br></br>
                                            {
                                                selectedContacts.length > 0 && (
                                                    <button className="btn btn-primary" onClick={() => handleSendInvite(EventID)}>Invite</button>
                                                )
                                            }
                                        </div>
                                        <div className="invitation-qr-code">
                                            <h5 className="text-title-2 text-center m-0">Invite connections below or via email.</h5>
                                            <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                                                <QRCode
                                                    size={256}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={window.location.origin + "/event-details?action=invited&id=" + EventID + "&referral=" + (currentUser ? currentUser.user.referral_code : '')}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                            <CopyToClipboard text={window.location.origin + "/event-details?action=invited&id=" + EventID + "&referral=" + (currentUser ? currentUser.user.referral_code : '')}
                                                onCopy={() => alert('Copied to clipboard !')}>
                                                <button className="text-title-3">
                                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9.73039 11.5101L7.55599 13.6845C6.22949 14.9755 4.10758 14.9468 2.81655 13.6203C1.55041 12.3194 1.55013 10.247 2.81589 8.94575L4.99029 6.76935C5.25057 6.50888 5.25042 6.08674 4.98995 5.82646C4.72948 5.56618 4.30734 5.56633 4.04706 5.8268L1.87331 8.0032C0.0441827 9.83327 0.0449326 12.7996 1.87497 14.6288C3.705 16.4579 6.67137 16.4571 8.50054 14.6271L10.6749 12.4527C10.9307 12.1879 10.9233 11.7659 10.6586 11.5102C10.4003 11.2607 9.9907 11.2607 9.73239 11.5102H9.73039V11.5101Z"
                                                            fill="#C5914B" />
                                                        <path
                                                            d="M15.1292 1.37386C14.253 0.491678 13.0602 -0.00307683 11.8169 1.65573e-05C10.5743 -0.00329555 9.38186 0.490428 8.50528 1.3712L6.32753 3.54626C6.06706 3.80654 6.06691 4.22868 6.32719 4.48915C6.58747 4.74962 7.00961 4.74977 7.27008 4.48949L9.44648 2.31509C10.0733 1.68448 10.9264 1.33092 11.8155 1.3332C13.6666 1.33383 15.1667 2.83487 15.166 4.6859C15.1657 5.5743 14.8127 6.42626 14.1846 7.05453L12.0102 9.22894C11.7497 9.4894 11.7497 9.9117 12.0102 10.1722C12.2706 10.4326 12.6929 10.4326 12.9534 10.1722L15.1278 7.99908C16.9544 6.16854 16.955 3.20508 15.1292 1.37386Z"
                                                            fill="#C5914B" />
                                                        <path
                                                            d="M10.029 5.52792L6.02943 9.52745C5.76461 9.78321 5.7573 10.2052 6.01305 10.47C6.2688 10.7348 6.69082 10.7421 6.9556 10.4864C6.96116 10.481 6.9666 10.4756 6.97197 10.47L10.9715 6.47047C11.2273 6.20566 11.2199 5.78367 10.9551 5.52792C10.6968 5.27845 10.2873 5.27845 10.029 5.52792Z"
                                                            fill="#C5914B" />
                                                    </svg>
                                                    Copy Link
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='modal fade' id="joinNotification">
                        <div className="modal-dialog" role="document">
                            <div className="modal-body">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-headline-3 m-0">Join Event Pool</h5>
                                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="pool-invitation-container">
                                        We welcome you to join the pools in this event.Or start your own.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    );

}

export default EventDetails; 