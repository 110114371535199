import React, { useState, useEffect, useRef } from "react";
import classes from './eventEdit.module.css';
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { renderToString } from 'react-dom/server';

import useMediaQuery from "../../hooks/useMediaQuery";
import UserService from "../../services/user.service";

import NavigationBar from '../../components/navigation/navigation';
import { clearMessage, setMessage } from "../../slices/message";
import SinglePool from '../../components/singlePool/singlePool';
import MultiplePool from "../../components/multiplePool/multiple";
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import { setProgress } from "../../slices/loader";


const EventEdit = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const poolFormRef = useRef(null);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const searchParams = useLocation();
    const [file, setFile] = useState();
    const [photoUrl, setPhotoUrl] = useState("");
    const { progress } = useSelector((state) => state.loader);

    const EventID = new URLSearchParams(searchParams.search).get("id");

    const [initialValues, setInitialValues] = useState({
        name: "",
        description: "",
        visibility: 1,
        address: "",
        city: "",
        state: "",
        maxMembersInPool: 0,
        maxPool: 0,
        eventDate: "",
        website: ""
    });


    useEffect(() => {
        dispatch(clearMessage());

        if (EventID) {
            dispatch(setProgress(progress + 50));

            Promise.all([
                PoolService.getPoolsEventById(EventID)
            ]).then((response) => {
                dispatch(setProgress(100));

                if (response[0].data.response_body.BettingPoolEvent) {

                    initialValues.name = response[0].data.response_body.BettingPoolEvent.name;
                    initialValues.description = response[0].data.response_body.BettingPoolEvent.description;
                    initialValues.visibility = response[0].data.response_body.BettingPoolEvent.visibility;
                    initialValues.address = response[0].data.response_body.BettingPoolEvent.address;
                    initialValues.city = response[0].data.response_body.BettingPoolEvent.city;
                    initialValues.state = response[0].data.response_body.BettingPoolEvent.state;
                    initialValues.maxMembersInPool = response[0].data.response_body.BettingPoolEvent.maxMembersInPool;
                    initialValues.maxPool = response[0].data.response_body.BettingPoolEvent.maxPool;
                    initialValues.eventDate = (new Date(response[0].data.response_body.BettingPoolEvent.eventDate).toISOString().split('T')[0]);
                    initialValues.website = response[0].data.response_body.BettingPoolEvent.website;

                    setFile(response[0].data.response_body.BettingPoolEvent.photo_id);
                    setPhotoUrl(response[0].data.response_body.BettingPoolEvent.photo ?
                        global.config.BASE_API_URL + '/public/' + response[0].data.response_body.BettingPoolEvent.photo :
                        response[0].data.response_body.BettingPoolEvent.photo);
                }


            }).catch((error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();

                dispatch(setMessage(_content));
                dispatch(setProgress(100));
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            });
        }

    }, [dispatch]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required!"),
        description: Yup.string().required("This field is required!"),
        visibility: Yup.string().required("This field is required!"),
        maxMembersInPool: Yup.string().required("This field is required!"),
        maxPool: Yup.string().required("This field is required!"),
        eventDate: Yup.string().required("This field is required!")
    });

    const handleEventCreate = function (formValue) {

        const { name, description, visibility, address, city, state, maxMembersInPool, maxPool, eventDate, website } = formValue;

        setLoading(true);

        var photo = file ? file : null;

        PoolService.updatePoolsEvent(
            EventID,
            name,
            description,
            visibility,
            address,
            city,
            state,
            maxMembersInPool,
            maxPool,
            eventDate,
            photo,
            currentUser.user.id,
            website
        ).then(
            (response) => {
                setLoading(false);

                navigate({
                    pathname: '/event-details',
                    search: '?id=' + response.data.response_body.BettingPoolEvent.id,
                });

            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();


                setLoading(false);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }

                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }
            }
        );

    }

    const triggerPoolSubmit = () => {

        if (poolFormRef.current) {
            poolFormRef.current.handleSubmit();
        }
    }

    const handleFileChange = (e) => {
        if (!e.target.files) {
            return;
        }

        UserService.fileUpload(e.target.files).then(
            (response) => {
                setLoading(false);

                setFile(response.data.response_body.File[0]);
                setPhotoUrl(response.data.response_body.File[0].url);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                dispatch(setMessage(_content));


                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );

    };

    if (!currentUser) {
        let formatReturnUrl = encodeURIComponent(`${searchParams.pathname}${searchParams.search}`);
        let returnUrl = `?returnUrl=${formatReturnUrl}`;
        return <Navigate to={`/login${returnUrl}`} />;
    }

    return (
        < >

            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Edit Event" }} />

                    <div className="create-new-pool-area">
                        <div className="pool-topbar d-flex justify-content-between">
                            <div className="back-button-area d-flex align-items-center">
                                <button >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round" />
                                        <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                                <h2 className="text-title-1 m-0">Edit Event</h2>
                            </div>
                            <div className="pool-buttons-area">
                                <button className="cancel-new-pool text-title-4" onClick={() => { navigate(-1) }} >Cancel</button>
                                <button className="publish-new-pool text-title-4" onClick={triggerPoolSubmit}>Update Event</button>
                            </div>
                        </div>
                    </div>
                    <div className="select-type-of-pool">
                        <div className="create-single-pool-area">
                            <div className="pool-cover-image">
                                <label className="pool-cover-image-label" htmlFor="pool-cover-image">
                                    <img src={photoUrl ? photoUrl : "assets/img/new-pool/upload-pool-cover.png"} alt="pool-cover-image" />
                                </label>
                                <input className="upload-pool-cover-image" onChange={(e) => handleFileChange(e)} type="file" id="pool-cover-image" />
                            </div>
                            <div className="pool-data-inputs">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleEventCreate}
                                    enableReinitialize={true}
                                    innerRef={poolFormRef}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className="data-input-item">
                                                <label htmlFor="name">Event name</label>
                                                <div>
                                                    <Field
                                                        name="name"
                                                        type="text"
                                                        className={
                                                            "form-control" +
                                                            (errors.name && touched.name ? " is-invalid" : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name="name"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="data-input-item">
                                                <label htmlFor="description">Description</label>
                                                <div>
                                                    <Field
                                                        name="description"
                                                        component="textarea"
                                                        rows={10}
                                                        className={
                                                            `${classes.picker}` +
                                                            " form-control" +
                                                            (errors.description && touched.description ? " is-invalid" : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name="description"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="data-input-item">
                                                <label htmlFor="visibility">Event visibility</label>
                                                <Field as="select" name="visibility" className={"nice-select"}>
                                                    <option value="0" defaultValue={{ value: 0 }}>Private</option>
                                                    <option value="1">Public</option>
                                                </Field>
                                            </div>
                                            <div className="data-input-item">
                                                <label htmlFor="address">Event Address</label>
                                                <div>
                                                    <Field
                                                        name="address"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="data-input-item">
                                                <label htmlFor="city">Event City</label>
                                                <div>
                                                    <Field
                                                        name="city"
                                                        type="text"
                                                        className={"form-control"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="data-input-item">
                                                <label htmlFor="state">Event State</label>
                                                <div>
                                                    <Field
                                                        name="state"
                                                        type="text"
                                                        className={"form-control"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="data-input-item">
                                                <label htmlFor="maxMembersInPool">Max Members In Pool</label>
                                                <div>
                                                    <Field
                                                        name="maxMembersInPool"
                                                        type="number"
                                                        className={
                                                            "form-control" +
                                                            (errors.maxMembersInPool && touched.maxMembersInPool ? " is-invalid" : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name="maxMembersInPool"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="data-input-item">
                                                <label htmlFor="maxPool">Max Pools</label>
                                                <div>
                                                    <Field
                                                        name="maxPool"
                                                        type="number"
                                                        className={
                                                            "form-control" +
                                                            (errors.maxPool && touched.maxPool ? " is-invalid" : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name="maxPool"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="data-input-item">
                                                <label htmlFor="eventDate">Event Date</label>
                                                <div>
                                                    <Field
                                                        name="eventDate"
                                                        type="date"
                                                        className={
                                                            `${classes.picker}` +
                                                            " form-control" +
                                                            (errors.eventDate && touched.eventDate ? " is-invalid" : "")
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        name="eventDate"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </div>
                                            <div className="data-input-item">
                                                <label htmlFor="website">Website</label>
                                                <div>
                                                    <Field
                                                        name="website"
                                                        type="text"
                                                        className={" form-control"}
                                                    />
                                                </div>
                                            </div>
                                            <br></br>
                                        </Form>
                                    )}
                                </Formik>
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>);

}
export default EventEdit;