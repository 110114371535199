import axios from "axios";
import "../../config";

const API_URL = global.config.API_URL + 'user/';

const getAccountByUserId = () => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "me", { headers });
}

const updateAccountByUserId = (userId, firstName, lastName, date_of_birth, phone, photo_id) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.post(API_URL + "update-account-by-userId", {
        userId,
        firstName,
        lastName,
        date_of_birth,
        phone,
        photo_id
    }, { headers });
}

const addContactUs = (firstName, lastName, email, message) => {
    return axios.post(API_URL + "contact-us", {
        firstName,
        lastName,
        email,
        message
    });
}

const fileUpload = (files) => {

    const data = new FormData();

    [...files].forEach((file, i) => {
        data.append(`files`, file)
    })

    return axios.post(global.config.API_URL + 'media/upload', data, {
        headers: {
            'authorization': `${JSON.parse(localStorage.getItem("user")).token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
}

const getAllPlans = () => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "plans", { headers });
}

const addSubscription = (userId, subscriptionId, planId, amount) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.post(API_URL + "subscription", {
        userId,
        subscriptionId,
        planId,
        amount
    }, { headers });
}

const getSubscription = (userId) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "subscription/" + userId, { headers });
}

const cancelSubscription = (subscriptionId) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.post(API_URL + "cancel-subscription", {
        subscriptionId
    }, { headers });
}

const getSubscriptionHistory = (userId) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "subscription-history/" + userId, { headers });
}

const getSubscriptionPayments = (userId) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "subscription-payments/" + userId, { headers });
}

const addOrUpdateSocialMediaLinks = (link, social_media, createdBy, modifiedBy) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.post(API_URL + "social-media-links", {
        link, social_media, createdBy, modifiedBy
    }, { headers });
}

const getSocialMediaLinksByUserId = (createdBy) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "social-media-links-by-userId/" + createdBy, { headers });
}

const searchUsers = (requestUserId, searchTerm) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "search-users/" + requestUserId + "/" + searchTerm, { headers });
}

const getUserSettings = (userId) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + userId + "/settings", { headers });
}

const updateUserSettings = (userId, settings) => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.put(API_URL + userId + "/settings", {
        settings
    }, { headers });
}

const getUserReferrals = () => {
    let headers = { 'authorization': `${JSON.parse(localStorage.getItem("user")).token}` };
    return axios.get(API_URL + "referrals", { headers });
}

const UserService = {
    getAccountByUserId,
    updateAccountByUserId,
    addContactUs,
    fileUpload,
    getAllPlans,
    addSubscription,
    getSubscription,
    cancelSubscription,
    getSubscriptionHistory,
    getSubscriptionPayments,
    addOrUpdateSocialMediaLinks,
    getSocialMediaLinksByUserId,
    searchUsers,
    getUserSettings,
    updateUserSettings,
    getUserReferrals
}

export default UserService;