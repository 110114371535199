import classes from './poolEdit.module.css';
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Moment from 'moment';

import { Navigate, useNavigate, Link, useSearchParams, useLocation, json } from "react-router-dom";
import NavigationBar from '../../components/navigation/navigation';
import PoolService from "../../services/pool.service";
import EventBus from "../../common/eventBus";
import UserService from "../../services/user.service";
import { renderToString } from 'react-dom/server';

import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import QRCode from "react-qr-code";
import Multiselect from 'multiselect-react-dropdown';
import useMediaQuery from "../../hooks/useMediaQuery";
import { clearMessage, setMessage } from "../../slices/message";
import "../../../config";
import Switch from "react-switch";


const PoolEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParams = useLocation();
    const { message } = useSelector((state) => state.message);
    const [loading, setLoading] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const PoolID = new URLSearchParams(searchParams.search).get("id");
    const [pool, setPool] = useState({});
    const [file, setFile] = useState();
    const [photoUrl, setPhotoUrl] = useState("");
    const poolEditFormRef = useRef(null);
    const [pageStatus, setPageStatus] = useState("");
    const [initialValues, setInitialValues] = useState({
        name: "",
        defaultBuy_in_amount: 0,
        goal_amount: 0,
        maxMembers: 0,
        pickDeadline: '',
        votingDeadline: '',
        description: '',
        is_private: "0"
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required!"),
        defaultBuy_in_amount: Yup.string().required("This field is required!"),
        goal_amount: Yup.string().required("This field is required!"),
        maxMembers: Yup.string().required("This field is required!"),
        pickDeadline: Yup.string().required("This field is required!"),
        votingDeadline: Yup.string().required("This field is required!"),
        description: Yup.string().required("This field is required!")
    });

    useEffect(() => {
        dispatch(clearMessage());
        if (PoolID) {

            PoolService.getPoolsById(PoolID).then(
                (response) => {
                    let fetchedPool = response.data.response_body.Pool;

                    setPhotoUrl((typeof (fetchedPool.photo) !== "undefined" && fetchedPool.photo !== null) ?
                        global.config.BASE_API_URL + '/public/' + fetchedPool.photo :
                        fetchedPool.photo);

                    setFile({ id: fetchedPool.photo_id });

                    setPool(fetchedPool);

                    setInitialValues({
                        name: fetchedPool.name,
                        defaultBuy_in_amount: fetchedPool.defaultBuy_in_amount,
                        goal_amount: fetchedPool.goal_amount,
                        maxMembers: fetchedPool.PoolsSettings[0].maxMembers,
                        pickDeadline: (new Date(fetchedPool.PoolsSettings[0].pickDeadline).toISOString().split('T')[0]),
                        votingDeadline: (new Date(fetchedPool.PoolsSettings[0].votingDeadline).toISOString().split('T')[0]),
                        description: fetchedPool.description,
                        is_private: fetchedPool.is_private ? fetchedPool.is_private : "0"
                    });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();

                    dispatch(setMessage(_content));

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }

                }
            );
        }

    }, [dispatch]);


    const handleSettingsUpdate = function (formValue) {

        const { name, description, defaultBuy_in_amount, goal_amount, maxMembers, pickDeadline, votingDeadline, is_private } = formValue;

        setLoading(true);

        var photo = file ? file.id : null;

        if (currentUser) {

            PoolService.addOrUpdatePoolSettings(
                PoolID,
                name,
                description,
                parseFloat(defaultBuy_in_amount),
                parseFloat(goal_amount),
                parseInt(maxMembers),
                new Date(pickDeadline),
                new Date(votingDeadline),
                currentUser.user.id,
                photo,
                is_private
            ).then(
                (response) => {
                    setLoading(false);

                    navigate({
                        pathname: '/pool-details',
                        search: '?id=' + response.data.response_body.BettingPool.id,
                    });

                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.response_message) ||
                        error.message ||
                        error.toString();


                    setLoading(false);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }

                    if (error.response && error.response.status === 500) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }
    }

    const handleFileChange = (e) => {
        if (!e.target.files) {
            return;
        }

        UserService.fileUpload(e.target.files).then(
            (response) => {
                setLoading(false);

                setFile(response.data.response_body.File[0]);
                setPhotoUrl(response.data.response_body.File[0].url);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.response_message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                dispatch(setMessage(_content));


                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
                if (error.response && error.response.status === 500) {
                    EventBus.dispatch("logout");
                }

            }
        );

    };

    const triggerPoolEditSubmit = () => {

        if (poolEditFormRef.current) {
            poolEditFormRef.current.handleSubmit();
        }
    }



    if (!currentUser) {
        let formatReturnUrl = encodeURIComponent(`${searchParams.pathname}${searchParams.search}`);
        let returnUrl = `?returnUrl=${formatReturnUrl}`;
        return <Navigate to={`/login${returnUrl}`} />;
    }


    return (
        < >
          
            <main>
                <div className="cip-dashboard-body">
                    <NavigationBar props={{ type: 0, pageName: "Pool Edit" }} />

                    <div className="pool-details-area">
                        <div className="pool-topbar d-flex justify-content-between">
                            <div className="back-button-area d-flex align-items-center">
                                <button onClick={() => navigate(-1)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8333 10H4.16666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M9.99999 15.8332L4.16666 9.99984L9.99999 4.1665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </button>
                                <h2 className="text-title-1 m-0">Pool Edit</h2>
                            </div>

                            {((Object.keys(pool).length > 0)) && (pool.ownerID == currentUser.user.id) && (
                                <div className="pool-buttons-area">
                                    <button className="cancel-new-pool text-title-4" onClick={() => navigate(-1)} >Cancel</button>
                                    <button className="publish-new-pool text-title-4" onClick={triggerPoolEditSubmit} >Update Pool</button>
                                </div>
                            )}

                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        {pageStatus && (
                            <div className="form-group">
                                <div className="alert alert-success" role="alert">
                                    {pageStatus}
                                </div>
                            </div>
                        )}

                        {
                            ((Object.keys(pool).length > 0)) && (pool.ownerID == currentUser.user.id) && (
                                <>
                                    <br></br>
                                    <div className="card">
                                        <div className="card-header">
                                            Edit
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className="col-4">
                                                    <div className="pool-cover-image">
                                                        <label className="pool-cover-image-label" htmlFor="pool-cover-image">
                                                            <img src={photoUrl ? photoUrl : "assets/img/new-pool/upload-pool-cover.png"} alt="pool-cover-image" />
                                                        </label>
                                                        <input className="upload-pool-cover-image" onChange={(e) => handleFileChange(e)} type="file" id="pool-cover-image" />
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <Formik
                                                        initialValues={initialValues}
                                                        validationSchema={validationSchema}
                                                        onSubmit={handleSettingsUpdate}
                                                        enableReinitialize={true}
                                                        innerRef={poolEditFormRef}
                                                    >
                                                        {({ errors, touched }) => (
                                                            <Form>
                                                                <div className="data-input-item">
                                                                    <label htmlFor="name">Pool name</label>
                                                                    <div>
                                                                        <Field
                                                                            name="name"
                                                                            type="text"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.name && touched.name ? " is-invalid" : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="name"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="data-input-item">
                                                                    <label htmlFor="defaultBuy_in_amount">Default buy in amount</label>
                                                                    <div>
                                                                        <Field
                                                                            name="defaultBuy_in_amount"
                                                                            type="number"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.defaultBuy_in_amount && touched.defaultBuy_in_amount ? " is-invalid" : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="defaultBuy_in_amount"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="data-input-item">
                                                                    <label htmlFor="goal_amount">Goal amount</label>
                                                                    <div>
                                                                        <Field
                                                                            name="goal_amount"
                                                                            type="number"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.goal_amount && touched.goal_amount ? " is-invalid" : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="goal_amount"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="data-input-item">
                                                                    <label htmlFor="maxMembers">Max allowed members</label>
                                                                    <div>
                                                                        <Field
                                                                            name="maxMembers"
                                                                            type="number"
                                                                            className={
                                                                                "form-control" +
                                                                                (errors.maxMembers && touched.maxMembers ? " is-invalid" : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="maxMembers"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="data-input-item">
                                                                    <label htmlFor="pickDeadline">Pick deadline</label>
                                                                    <div>
                                                                        <Field
                                                                            name="pickDeadline"
                                                                            type="date"
                                                                            className={
                                                                                `${classes.picker}` +
                                                                                " form-control" +
                                                                                (errors.pickDeadline && touched.pickDeadline ? " is-invalid" : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="pickDeadline"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="data-input-item">
                                                                    <label htmlFor="votingDeadline">Voting deadline</label>
                                                                    <div>
                                                                        <Field
                                                                            name="votingDeadline"
                                                                            type="date"
                                                                            className={
                                                                                `${classes.picker}` +
                                                                                " form-control" +
                                                                                (errors.votingDeadline && touched.votingDeadline ? " is-invalid" : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="votingDeadline"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="data-input-item">
                                                                    <label htmlFor="is_private">Pool visibility</label>
                                                                    <Field as="select" name="is_private" className={"nice-select"}>
                                                                        <option value="0" defaultValue={{ value: 0 }}>Public</option>
                                                                        <option value="1">Private</option>
                                                                    </Field>
                                                                </div>
                                                                <div className="data-input-item">
                                                                    <label htmlFor="description">Description</label>
                                                                    <div>
                                                                        <Field
                                                                            name="description"
                                                                            component="textarea"
                                                                            rows={10}
                                                                            className={
                                                                                `${classes.picker}` +
                                                                                " form-control" +
                                                                                (errors.description && touched.description ? " is-invalid" : "")
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <br></br>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                    <br></br>
                                                    <br></br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>)

                        }
                        {
                            ((Object.keys(pool).length > 0)) && (pool.ownerID != currentUser.user.id) && (
                                <>
                                    <br></br>
                                    <div className="alert alert-primary" role="alert">
                                        Members do not have access to pool settings.
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div >
            </main >
        </>
    )

}

export default PoolEdit;